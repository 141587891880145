@use "sass:math";
@import "flyte_vars";

@mixin fz-gradient {
  background: linear-gradient(242deg, $color-mango -22%, $color-peach 100%) !important;
}

@mixin fz-gradient-side-nav {
  background: linear-gradient(264deg, $color-mango -22%, $color-peach 100%);
}

@mixin fz-border-top-dashed {
  background-image: linear-gradient(to left, #373737 47%, rgba(255, 255, 255, 0) 0%);
  background-position: top;
  background-size: 12px 2px;
  background-repeat: repeat-x;
  background-color: $color-black-glass-15;
}

@mixin bi-font($icon-code) {
  display: inline-block;
  font-family: bootstrap-icons !important;
  font-style: normal;
  font-weight: normal !important;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  vertical-align: -.125em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: $icon-code;
}

@mixin form-control-light-theme {
  .form-control, .form-select {
    background: $color-gray-light;
    color: $color-charcoal1;
    border-color: $color-gray-light;
    &.is-valid {
      border-color: $color-gray-light;
    }
    &:-webkit-autofill {
      box-shadow: inset 0 0 10px 1000px #cecece !important;
      -webkit-text-fill-color: $color-charcoal1;
    }
    &[disabled], &[readonly] {
      color: fade_out($color-charcoal1, 0.5);
      border-color: transparent;
    }
    &[readonly] { cursor: default;}
  }
  .form-floating.form-floating-sm > .form-control:not(:placeholder-shown) ~ label { color: $form-label-color; }
  .form-floating {
    > label,
    > bo-label label {
      color: $form-label-color !important;
    }
    &.show-placeholder .form-control {
      &::placeholder { color: $color-black-glass-20 !important; }
    }
  }
  .form-check {
    .form-check-input {
      border-color: darken($color-gray-light, 5%);
    }
    .form-check-label {
      color: $color-charcoal1;
    }
  }
  i.bi {
    color: $form-label-color !important;
  }
  .ng-select {
    &.ng-select-opened > .ng-select-container {
      background-color: $color-gray-light !important;
      border-color: $color-gray-light !important;
    }
    &.ng-select-focused:not(.ng-select-opened)>.ng-select-container {
      border-color: $color-gray-light;
    }
    &.ng-select-single {
      .ng-select-container {
        height: 58px;
        .ng-value-container {
          height: 56px;
          margin-left: 1px;
          border-radius: 7px;
        }
      }
    }
    .ng-select-container {
      background: $color-gray-light;
      border-color: $color-gray-light;
      color: $color-charcoal1;
      .ng-value-container {
        background: $color-gray-light;
        .ng-placeholder {
          color: $form-label-color;
        }
        .ng-input input {
          color: $color-charcoal1 !important;
        }
      }
      &.ng-has-value {
        .ng-placeholder {
          transform: scale(0.8) translateY(-1rem);
        }
        .ng-value, .ng-input {
          padding-top: 23px;
        }
      }
    }
    .ng-clear-wrapper {
      color: $color-charcoal1;
    }
  }
  .int-tel-field {
    > bo-label label {
      color: $form-label-color;
    }
    .ng-invalid.ng-touched>.iti .form-control {
     border-color: $danger;
    }
    > .ng-untouched.ng-dirty {
      .form-control { border-color: $color-gray-light;}
    }
    .iti__arrow {
      border-top-color: $color-charcoal1;
    }
  }
}

@mixin card-light-theme {
  .card {
    background: white;
    color: $color-charcoal1;
    &-outline {
      border-color: $color-black-glass-15;
    }
  }
}

@mixin action-icon {
  display: inline-block;
  font-size: 1rem;
  line-height: 1rem;
  color: white;
  vertical-align: middle;
  transition: all 0.2s ease-in-out;
  [disabled] { opacity: 0.5; }
  &:not([disabled]):not(.text-muted):hover { color: $primary;  cursor: pointer;}
}

@mixin subnav-link {
  color: white;
  font-size: 0.875rem;
  display: block;
  padding-bottom: 3px;
  margin-bottom: 5px;
  position: relative;
  opacity: 0.8;
  cursor: pointer;
  transition: opacity 0.2s ease-in-out;
  &:hover {
    opacity: 1;
    text-decoration: none;
  }
  &::after {
    display: block;
    content: '';
    height: 2px;
    width: 0;
    position: absolute;
    bottom:0;
    left: 50%;
    transform: translateX(-50%);
    @include fz-gradient;
    transition: all 0.2s ease-in-out;
  }
}

@mixin alert-box-style($color, $bg-color) {
  color: $color;
  background: $bg-color;
  border-color: $color;
  a { color: $color; }
}

@mixin subnav-link-active {
  opacity: 1;
  font-weight: 600;
  &::after {
    width: 100%;
  }
}

@mixin reset-accordion-style {
  .panel-group {
    > .panel {
      .panel-default {
        margin:0;
        border-radius: 0;
        background-color: transparent;
        .panel-heading {
          border-radius: 0;
          font-weight: $font-weight-base;
          font-size: $font-size-base;
          padding: 0;
          letter-spacing: normal;
        }
        .panel-title {
          .btn {
            //padding:0;
            //display: block;
          }
        }
      }
      .panel-body {
        padding:0;
      }
    }
  }
}

@mixin accordion-heading-arrow {
  .panel-heading {
    position: relative;
    padding-right: 2rem !important;
    &::after {
      @include bi-font("\f229");
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0.8rem;
      transition: all 0.2s ease-in-out;
    }
  }
  .panel-open {
    .panel-heading {
      &::after {
        transform: translateY(-50%) rotate(180deg);
      }
    }
  }
}

@mixin accordion-style-card {
  .panel-group {
    > .panel {
      background: transparent;
      border: none;
      margin-bottom: $card-spacer-y;
      .panel-default {
        background: $color-white-glass-10;
        border-radius: $border-radius-sm;
        height: 100%;
        transition: all 0.2s ease-in-out;
        &:hover {
          background: $color-white-glass-30;
        }
        .panel-heading {
          letter-spacing: normal;
          padding: math.div($card-spacer-y, 1.5) $card-spacer-x;
          font-size: $font-size-base;
          font-weight: $font-weight-base;
        }
        .panel-body {
          background: $color-charcoal1;
          padding: $card-spacer-y $card-spacer-x;
          border-radius: $border-radius-sm;
          margin: 2px;
        }
      }
      &.panel-open {
        .panel-default {
          background: $color-white-glass-30;
        }
      }
    }
  }
}

@mixin light-theme {
  @include card-light-theme;
  @include form-control-light-theme;
}

@mixin calcPageHeight($variableInput) {
  height: calc(100vh - #{$main-container-fixtures-height} - #{$operations-subnav-height + $variableInput});
}
