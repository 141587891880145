
/* File upload container */
.loader.drop-container { display: none;}
.drop-container {
  border: 3px dashed $color-white-glass-10;
  border-radius: 18px;
  width: 100%;
  margin-bottom: $grid-gutter-width;
  padding: $spacer;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: all 0.2s ease-in-out;
  min-height: 180px;
  &.is-drop-over {
    background: fade_out($primary, 0.9);
    border-color: $primary;
    i::before { color: $primary; }
  }
  i::before {
    @include bi-font("\f2c0");
    color: $color-white-glass-30;
    font-size: 50px;
    margin-top: $spacer;
    margin-bottom: $spacer;
    transition: all 0.2s ease-in-out;
  }
  .upload-button {
    background: $primary;
    color: white;
    &:hover { color: white; }
  }

  label {
    display: block;
    margin: 1rem auto;
    input {
      display: none;
    }
  }
}

.cropper-buttons {
  text-align: end;
  margin-top: 1rem;
  .btn + .btn { margin-left: 1rem; }
}
