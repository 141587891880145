@use "sass:math";

//FZ variable over-writes
@import "_flyte_vars";
@import "_flyte_mixins";

//bootstrap
@import "bootstrap/scss/bootstrap";
@import "../bootstrap-icons/font/bootstrap-icons.min.css";

//Swiper
//@import "~swiper/swiper.min.css";
@import "swiper/swiper-bundle.css";

//larger custom standalone components
@import "components/avatars/avatars";
@import "components/toastr/toastr";
@import "components/datatable/datatable";
@import "components/ng-select/ng-select";
@import "components/datepicker/datepicker";
@import "components/int-tel-input/int-tel-input";
@import "components/owldatetimepicker/owl-datetimepicker";
@import "components/fullcalendar/fullcalendar";
@import "components/fz-ui-tour/fz-ui-tour";


//general
@import "../fz-icons/style.css";
@import "main-nav";
@import "framework";
@import "forms";
@import "components/upload";
@import "weather";
@import "resizable";
@import "onboarding";

:root {
  --swiper-navigation-size: 30px;
}

body, html {
  min-width: 280px;
}

*:not(.ng-dropdown-panel-items, .iti__country-list) {

  &::-webkit-scrollbar {
    width: 14px;
    background: transparent;
    padding-left: 5px;
  }

  &::-webkit-scrollbar-track {
    padding: 3px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $color-white-glass-30;
    outline: none;
    border-radius: 10px;
    border: 4px solid $body-bg;
  }
}

.dropdown-menu::-webkit-scrollbar-thumb {
  border: 4px solid $color-charcoal2 !important;
}

*:focus { outline: none ; }

//=== Typography & Utils =============================================================

a:not(.btn):hover {
  text-decoration: underline;
}

.link {
  cursor: pointer;
}

.text-link {
  cursor: pointer;
  color: $primary;
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  &:hover { text-decoration: underline !important; }
  &[disabled] { opacity: 0.5; pointer-events: none; }
}

.small { font-size: $font-size-sm; line-height: $line-height-sm; }

.text-transparent { opacity: 0; }
.text-reduce-lh { line-height: 1.2; }

.disabled-view {
  opacity: 0.5 !important;
}

strong, .text-strong { font-weight: 700; }

.vert-middle { vertical-align: middle; }

.btn {
  text-transform: capitalize;
  &-primary, &-secondary, &-danger {
    color: white;
    &:hover, &:focus { color: white; }
    &[disabled] { color: white; }
  }
  &-outline-white {
    border-color: $color-white-glass-40;
    background: transparent;
    color: white;
    &:hover, &:active, &:focus {
      background: $color-white-glass-30 !important;
      border-color: $color-white-glass-40;
      color: white;
    }
    &.active {
      border-color: white;
    }
  }
  &-white {
    background: white;
    color: black;
    &:hover, &:active, &:focus {
      color: white;
      background: $primary;
    }
  }
  &-success, &-danger {
    color: white;
    &:disabled, &.disabled {
      color: white;
    }
  }
  &-close {
    cursor: pointer;
  }
  &-white-glass-10 {
    background: $color-white-glass-10;
    border-color: transparent;
    font-weight: normal;
    &[disabled] {
      border-color: transparent;
      background: $color-white-glass-10;
    }
    &:not([disabled]):hover {
      background: $color-white-glass-30;
    }
  }
  &-black-glass-10 {
    background: $color-black-glass-10;
    border-color: transparent;
    color: black;
    &:not([disabled]):hover {
      background: $color-black-glass-15;
      border-color: $color-black-glass-15;
      color: black;
    }
  }
  &-link {
    &:not([disabled]):hover, &:not([disabled]):focus {
      background-color: $color-white-glass-10;
      color: $primary;
    }
  }
  &-icon {
    white-space: nowrap;
    font-weight: 600;
    i {
      display: inline-block;
      vertical-align: middle;
      transition: all 0.1s ease-in-out;
      margin-right: 0.5rem;
      font-size: 1.3rem;
      line-height: 1.3rem;
    }
    &:not([disabled]):not(.text-muted):not(.btn-outline-primary):hover { color: white; }
    &.btn-sm {
      i {
        font-size: 1.1rem;
        line-height: 1.1rem;
      }
    }
    &.btn-outline-primary:hover i { color: black; }
    &.btn-outline-danger {
      &:hover i { color: black; }
      &[disabled]:hover i { color: $danger; }
    }
    @include media-breakpoint-down(md) {
      i { margin-right: 0; }
      .btn-text { display: none; }
    }
    &--right {
      i {
        margin-right: 0;
        margin-left: 0.5rem;
      }
    }
  }
}

.chip {
  display: inline-block;
  padding: 0.5rem 0.75rem;
  &-light {
    background: $color-white-glass-10;
  }
}

.btn.btn-map-pin {
  height: 46px;
  width: 40px;
  &.has-value[disabled] {
    background-color: $color-white-glass-20;
    cursor: auto;
  }
  &.active { background-color: $color-white-glass-50; }
  &:not(.active):hover { background-color: $color-white-glass-30; }
  &[disabled] {
    border-color: $color-charcoal2;
    &:hover { background-color: $color-charcoal2; }
  }
  &:focus {
    border-color: $input-focus-border-color;
  }
  background: {
    color: $color-white-glass-10;
    position: center center;
    repeat: no-repeat;
  }
  &-poi {
    background-size: auto 32px;
    background-position: 8px 8px;
    background-image: url('../../assets/img/map-markers/points-of-interest/ic-poi-default.svg');
  }
  &.poi {
    &-muster { background-image: url('../../assets/img/map-markers/points-of-interest/ic-muster.svg'); }
    &-vec { background-image: url('../../assets/img/map-markers/points-of-interest/ic-vehicle-access.svg'); }
    &-cordon { background-image: url('../../assets/img/map-markers/points-of-interest/ic-cordons.svg'); }
    &-spec { background-image: url('../../assets/img/map-markers/points-of-interest/ic-spectator.svg'); }
  }
  &-operation-takeoff {
    background-size: auto 30px;
    background-image: url('../../assets/img/map-markers/ic-takeoff.svg');
  }
  &-operation-landing {
    background-size: auto 30px;
    background-image: url('../../assets/img/map-markers/ic-landing.svg');
  }
  &-operation-em-landing {
    background-size: auto 30px;
    background-image: url('../../assets/img/map-markers/ic-emergency.svg');
  }

}

.order-6 { order: 6; }
.order-7 { order: 7; }
.order-8 { order: 8; }

.mw-auto {
  min-width: auto !important;
}

.height-auto {
  height: auto !important;
}

.max-70 { max-width: 70px; }

.block-muted {
  opacity: 0.4;
}

.mw-300 { min-width: 300px; }
.mw-150 { min-width: 150px; }

.bg-fz-gradient {
  @include fz-gradient;
}

.text-gradient {
  color: $primary;
  background: -webkit-linear-gradient(252deg, $color-mango -22%, $color-peach 100%);
  display: inline-block;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.normalize-pre {
  white-space: break-spaces;
  font-family: $font-family-base;
  font-size: $font-size-base;
}

.tooltip {
  position: absolute;
  .tooltip-inner { text-align: left; }
}
.tooltip-arrow { position: absolute; }

.toast-container {
  position: fixed;
}

.border-left {
  border-left: 1px solid $color-white-glass-20;
}

//=== Components =============================================================

.action-icon {
  position: relative;
  border: none;
  background-color: transparent;
  padding: 0;
  &::before {
    display: block;
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    background: $color-white-glass-10;
    border-radius: 100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: all 0.2s ease-in-out;
  }
  &:not(.btn) {
    @include action-icon;
  }
  &:not([disabled]):not(.disabled):not(.text-muted):hover::before {
    width: 36px;
    height: 36px;
  }
  &-hover-sm {
    &:not([disabled]):not(.text-muted):hover::before {
      width: 24px;
      height: 24px;
    }
  }
  &.disabled, &[disabled] { cursor: auto; opacity: 0.5}
  &:hover, &:hover i { text-decoration: none !important; }
}

.display-icon {
  font-size: 1.2rem;
  line-height: 1.2rem;
  &.larger {
    font-size: 1.5rem;
    line-height: 1.5rem;
  }
}

.card-style-two-tone {
  border-radius: $border-radius-sm;
  background: $color-white-glass-30;
  &.card-highlight {
    background: $primary !important;
  }
  &.card-highlight-danger {
    background: $danger;
  }
  .card-header {
    padding: 0.6rem 1rem;
    color: white;
    font-weight: 600;
    font-size: $font-size-base;
    letter-spacing: 0;
    border: none
  }
  .card-body {
    height: 100%;
    border-radius: $border-radius-sm;
    background-color: $body-bg;
    padding: $card-spacer-y $card-spacer-x;
    margin: 0 2px 2px 2px;
    z-index: 0;
  }
}

.operations-main-navigation {
  .btn-sm {
    margin-top: -3px;
    margin-bottom: -3px;
  }
}

.card {
  border-radius: $border-radius-sm;
  .card-body {
    &.center-content {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
    }
    .card-icon-lg i {
      font-size: 40px;
      line-height: 40px;
    }
    >.card-title:first-child { margin-top: 0; }
    >p:last-child { margin-bottom: 0; }
  }
  + .card { margin-top: $card-spacer-y; }
  .close-card-icon {
    position: absolute;
    right: $card-spacer-x;
    top: $card-spacer-y;
    font-size: 20px;
    line-height: 20px;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    &:hover {
      color: $primary;
    }
  }
  .card {
    border-radius: $border-radius-sm;
    + .card {
      margin-top: math.div($card-spacer-y, 2);
    }
  }
  .card-footer {
    padding:$card-spacer-y $card-spacer-x;
    text-align: end;
    .btn:not(.btn-sm) { min-width: 130px;}
    .btn + .btn { margin-left: $card-spacer-x}
  }
  .card-menu-btn {
    cursor: pointer;
    transition: all .2s ease-in-out;
    i {
      font-size: 1.5rem;
      line-height: 1;
    }
    &:hover { color: $primary; }
  }
  .dropdown-menu { min-width: 160px; }
  &--welcome {
    @include media-breakpoint-up(lg) {
      .card-body { padding: 2rem;}
    }
    .big-text {
      font-size: 2rem;
      font-weight: 700;
      margin-bottom: 1rem;
    }
  }
  &-title {
    margin-bottom: 0.5rem;
    font-weight: 600;
    margin-top: 1.2rem;
    text-transform: capitalize;
  }
  &-white {
    background: white;
    color: black;
  }
  &-sm {
    .card-body, .card-footer {
      padding: math.div($card-spacer-y, 1.6) math.div($card-spacer-x, 1.2);
    }
    .card-header {
      border-radius: 8px 8px 0 0
    }
  }
  &-xs {
    .card-body, .card-footer {
      padding: math.div($card-spacer-y, 2) math.div($card-spacer-x, 1.6);
    }
  }
  &-outline {
    background: none;
    border-color: $color-white-glass-10;
    border-width: 2px;
    &--light {
      border-color: $color-white-glass-30;
    }
    &--bg-light {
      background: rgba(255,255,255,.03);
      border: 1px solid rgba(255, 255, 255, 0.1);
    }
  }
  &-clickable {
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    &:hover { background-color: $color-white-glass-30; }
    &.card-outline:hover { border-color: $color-white-glass-30; }
  }
  &-header {
    border-radius: $card-border-radius;
    font-size: $h5-font-size;
    border-color: $color-white-glass-10;
    padding-top: math.div($card-spacer-y, 1.5);
    padding-bottom: math.div($card-spacer-y, 1.5);
    font-weight: 600;
    &-button {
      text-align: end;
      margin-bottom: $card-spacer-y;
    }
    &.card-header-block-link {
      display: block;
      cursor: pointer;
      transition: all 0.2s ease-in-out;
      color: white;
      background: lighten($card-bg, 5%);

      &:hover { text-decoration: none; }
      .info-item {
        font-weight: $font-weight-base;
      }
      .info-item__text {
        font-size: $font-size-base;
      }
    }
  }
  &:not(.card-disabled){
    .card-header.card-header-block-link:hover {
      background-color: $card-hover-bg;
    }
  }
  &-block-link {
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    a { color: white; }
    &[href] {
      color: white;
      &:hover { text-decoration: none; }
    }
    &:not(.card-disabled):hover {
      background-color: $card-hover-bg;
      a { text-decoration: none; }
    }
    .info-item {
      min-width: auto;
    }
  }
  &-block-icon {
    font-size: 1.5rem;
  }
  &-disabled {
    opacity: 0.6;
    cursor: default;
  }
  &-highlight {
    border-color: $primary !important;
  }
  &-light {
    background: $color-charcoal3;
  }
  &--operations {
    background: $card-bg-operations;
    height: calc(100vh - #{$main-container-fixtures-height + $operations-subnav-height});
    &.height-auto { height: auto; }
    &--light { background: $color-gray; }
    &__header {
      padding: 0.8rem 1rem;
      color: white;
      font-weight: 600;
      font-size: $font-size-base;
      .subnav,
      .operation-panel-subnav-swiper .swiper-slide,
      label{ font-weight: normal; }
      .form-control {
        background: $color-white-glass-10;
        border: $color-white-glass-10;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
      .btn-icon {
        margin-top: -7px;
        margin-bottom: -6px;
      }
      .generic-back-btn {
        padding: 0;
        .text {
          font-size: 0.875rem;
        }
        i {
          line-height: 1rem;
        }
      }
    }
    &__body {
      flex: 1;
      border-radius: $border-radius-sm;
      background-color: $body-bg;
      padding: $card-spacer-y $card-spacer-x;
      margin: 0 2px 2px 2px;
      overflow: auto;
      &.map-container {
        padding: 0;
        border-radius: 10px;
        overflow: inherit;
        position: relative;
        .map-search-overlay {
          position: absolute;
          z-index: 2;
          left: $card-spacer-y;
          top: $card-spacer-x;
          @include form-control-light-theme;
          .form-control {
            min-width: 300px;
            border-color: $color-black-glass-30 !important;
            background: white !important;
          }
        }
      }
      .page-title {
        font-size: $h5-font-size;
      }
      .operation-detail-status {
        position: absolute;
        right: 1rem;
        top: 7px;
      }
      .tab-link-style-subnav.hazards-btn-offset {
        margin-top: -45px;
        position: relative;
        .tab-content {
          padding-top: 1rem;
        }
      }
      @media (min-width: 1440px) {
        padding: $card-spacer-y*1.5 $card-spacer-x*1.5;
      }
    }
    .card--operations__footer-fixed {
      position: sticky;
      background: $card-bg-operations;
      bottom: 0;
      padding: 0.7rem 1rem;
      border-bottom-left-radius: $border-radius-sm;
      border-bottom-right-radius: $border-radius-sm;
      .bo-non-field-errors .alert {
        margin-top: 0;
      }
    }
  }
  &--operations-interaction-panel {
    .card--operations__body {
      padding:0;
      .card-body {
        background: $body-bg;
        border-radius: $border-radius-sm;
        &--map-locations {
          padding-top: calc(#{$card-spacer-y} + 5px);
        }
      }
      .weather-portrait-widget {
        background: $body-bg;
        border-radius: $border-radius-sm;
        padding: $card-spacer-y $card-spacer-x;
        height: 100%;
      }
    }
    .map-container {
      border-top-right-radius: $border-radius-sm;
      border-top-left-radius: $border-radius-sm;
      height: 340px;
      min-height: 100%;
      position: relative;
      z-index: 1;
    }
  }
  &--block-link-stop-propagation {
    padding-right: 50px;
    .block-link-options {
      position: absolute;
      right: $card-spacer-x;
      bottom: calc(#{$card-spacer-y} + 4px);

    }
  }
  &--audit-folder {
    > a {
      display: flex;
      margin-top: auto;
      margin-bottom: auto;
    }
    .audit-folder-download-link {
      position: absolute;
      right: 3.6rem;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  &--gear {
    @extend .card-style-two-tone;
    .card-body {
      .gear-card-icon i { font-size: 40px; }
    }
  }
}

.subscription-packages {
  @media (min-width: 1600px) {
    >.col {
      flex: 0 0 auto;
      width: 33.3333333333%;
    }
  }
}

.payment-response {
  min-height: calc(100vh - $main-container-fixtures-height);
  .card-body {
    margin-top: 10vh;
  }
  .icon {
    font-size: 150px;
    line-height: 150px;
  }
}

.signature-pad {
  color: black;
  margin-bottom: 0.5rem;
  &--body {
    width: 100%;
    min-width: 319px;
    height: 0;
    padding-bottom: 48%;
    background: white;
    border-radius: $border-radius-sm;
    position: relative;
    @include media-breakpoint-up(md) {
      min-width: 375px;
    }
  }
  &--write {
    width: 100%;
    height: 100%;
    position: absolute;
  }
}

.row-completion-status {
  &-spacer {
    display: block;
    padding-right: 30px;
    position: relative;
  }
  &__badge {
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    text-align: center;
    i {
      color: white;
      font-size: 12px;
      line-height: 22px;
    }
    &.complete {
      background: $success;
      i:before { @include bi-font("\f633"); }
    }
    &.incomplete {
      background: $warning;
      i:before { @include bi-font("\F63C");}
    }
  }
}

.suggestions-trigger {
  &::before {
    @include bi-font("\f64d");
      display: inline-block;
      vertical-align: middle;
      transition: all 0.1s ease-in-out;
      margin-right: 0.5rem;
      font-size: 1.3rem;
      line-height: 1.3rem;
  }
  &::after {
    content: 'Add';
  }
}

.suggestions {
  &-wrapper { position: relative; }
  &-info {
    opacity: 0.7;
    font-size: 12px;
    margin-bottom: -2px;
  }
  &-types {
    padding-bottom: 0.5rem;
    cursor: pointer;
    .type {
      opacity: 0.7;
      transition: all 0.2s ease-in-out;
      &:hover { opacity: 1; }
    }
    .selected { opacity: 1; }
  }
  &-content {
    text-align: start;
    background: $color-charcoal2;
    position: absolute;
    box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 35%);
    border-radius: $border-radius-sm;
    right: 0;
    top: 50px;
    width: 300px;
    z-index: 1;
    padding: $card-spacer-x $card-spacer-y;
  }
}

.tab-style-reset {
  >.tab-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    >.nav-tabs {
      border: none;
      margin-left: -0.5rem;
      .nav-link.active,
      .nav-item.show .nav-link {
        color: white;
        background: none;
        border: none;
        opacity: 1;
      }
      .nav-link {
        padding-left: 0;
        border: none !important;
        color: white;
        opacity: 0.7;
        text-decoration: none;
        &:hover { opacity: 1; }
      }
    }
    .tab-content {
      flex: 1 0 auto;
      .tab-pane.active {
        display: block;
        height: 100%;
      }
    }
  }
  &.tab-style-card-header {
    background: $card-bg-operations;
    border-radius: $border-radius-sm;
    height: 100%;
    >.tab-container>.nav {
      padding: 0.8rem 0.5rem;
      font-size: 0.875rem;
      margin-left: 0;
    }
  }
  &.tab-link-style-subnav {
    .nav-item { padding: 0 0.5rem;}
    .nav-link {
      padding: 0;
      cursor: pointer;
      @include subnav-link;
      margin-bottom: 0;
    }
    .nav-link.active,
    .nav-item.show .nav-link {
      @include subnav-link-active;
    }
  }
  &.tab-link-lg {
    .nav-link {
      font-size: 1rem ;
    }
  }
}

.suggestion-tabs {
  .nav-tabs { padding-left: 0.5rem; }
  .list-group {
    max-height: 30vh;
    overflow: auto;
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      border-color: $color-charcoal2 !important;
    }
  }
}

.card-list-titles {
  padding-left: $card-spacer-x;
  padding-right: $card-spacer-x;
  margin-bottom: 0.5rem;
  font-size: 12px;
  opacity: 0.7;
  // clean this up - temp hasty styling
  .row-numbered-list-spacer {
    padding-left: 15px;
  }
  .row-numbered-list-avatar-spacer {
    padding-left: 63px;
  }
  .row-numbered-list-remove-spacer {
    padding-right: 34px;
  }
  .row-numbered-list-badge-spacer {
    padding-right: 55px;
  }
}

.point-wrapper {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  .point {
    &-icon {
      flex: 0 0 auto;
      text-align: center;
      width: 28px;
      margin-right: 0.7rem;
      img { max-width: 28px; }
    }
    &-info {
      flex: 0 0 auto;
      max-width: 100%;
      width: 80%;
      .info-item__text { line-height: 1.1}
    }
  }
}

.row-hazard-marker {
  &-spacer {
    position: relative;
    padding-right: 40px;
  }
  &__icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
  }
}

.hazard-sign-off-status-indicator {
  position: absolute;
  top: -2px;
  left: 137px;
}

.show-hazard-decline-buttons {
  padding-left: 40px;
  .hazard-decline-buttons {
    position: absolute;
    left: -2.5rem;
  }
}

.hazard-rating-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  max-width: 150px;
  margin-bottom: 1rem;
  .hazard-rating {
    margin-right: .2rem;
    margin-bottom: .2rem;
  }
}
.hazard-rating {
  display: inline-block;
  border-radius: 4px;
  padding: 1px 10px;
  margin-bottom: -2px;
  color: white;
  min-width: 39px;
  white-space: nowrap;
  &-5 {
    &A, &B, &C { background: $color-brightred; }
    &D, &E { background: darkorange; }
  }
  &-4 {
    &A, &B { background: $color-brightred; }
    &C, &D, &E { background: darkorange; }
  }
  &-3 {
    &A { background: $color-brightred; }
    &B, &C, &D { background: darkorange; }
    &E { background: green; }
  }
  &-2 {
    &A, &B, &C { background: darkorange; }
    &D, &E { background: green; }
  }
  &-1 {
    &A, &B, &C, &D, &E { background: green; }
  }
}

.location-tools-wrap {
  max-width: 75%;
}

.map-locations-toggle {
  padding: 0 0.6rem 0.5rem 0.6rem;
  position: relative;
  background: white;
  border-bottom-left-radius: $border-radius;
  border-bottom-right-radius: $border-radius;
  .btn {
    &-light {
      background: $color-black-glass-10;
      &:hover {
        border-color: transparent;
        background: $color-black-glass-15;
        color: black;
      }
    }
    width: 100%;
    font-weight: normal;
    font-size: 12px;
  }
}

.accordion {
  &--hazards, &--emergency-services, &--poi, &--flight-detail {
    @include accordion-style-card;
    .btn + .btn { margin-left: $card-spacer-x; }
    .panel-group {
      display: flex !important;
      flex-direction: column;
    }
    textarea.form-control { min-height: 80px;}
  }
  &--hazards {
    .panel {position: relative; }
    .ng-select { min-width: 120px; }
    .info-item__label { opacity: 0.6; }
    @include accordion-style-card;

    .hazard-completion-status {
      position: absolute;
      right:-30px;
      top: 50%;
      transform: translateY(-50%);
    }

  }
  &--flight-detail {
    @include accordion-heading-arrow;
  }
}

.map-zoom-button {
  position: absolute;
  bottom: 1.5rem;
  right: 3.75rem;
  width: 32px;
  z-index: 1;
  min-width: auto;
  padding-left: 5px;
  padding-right: 5px;
}

.google-map {
  width: 100%;
  min-height: 100%;
  color: black;
  &__messages {
    position: absolute;
    top: -40px;
    right: $card-spacer-x;
    width: 200px;
    z-index: 2;
    height: 32px;
    text-align: right;
    .btn {
      margin-left: auto
    }
    ul {
      margin-top: 20px;
      text-align: left;
      margin-bottom:0;
      padding: 0;
      list-style: none;
      font-size: 12px;
      box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 35%);
      border-radius: $border-radius-sm;
      z-index: 1;
      overflow-y: auto;
      background: white;
      li {
        background: white;
        position: relative;
        padding: 7px 5px 7px 36px;
        min-height: 32px;
        color: black;
        + li {
          border-top: 1px solid $color-black-glass-30;
        }
        .message-icon {
          display: block;
          font-size: 20px;
          position: absolute;
          top: 5px;
          left: 8px;
        }
        &.message-top {
          position: absolute;
          top: 0;
          min-height: 33px;
          width: 200px;
          text-align: right;
          z-index: 1;
          background-color: $card-bg-operations;
          color: white;
          padding: 5px 32px 5px 5px;
          .message-icon {
            left: auto;
            right: 0;
            top: 50%;
            font-size: inherit;
            transform: translateY(-50%);
            &.text-success { color: #44d644 !important; }
          }
        }
      }
    }
    ul.contain-size {
      max-height: 50vh;
      &::-webkit-scrollbar-thumb {
        background-color: $color-charcoal2;
        outline: none;
        border-radius: 10px;
        border: 4px solid white;
      }
    }
  }
  .gm-style{
    .gmnoprint {
      >div[draggable="false"] { border-radius: 4px !important; }
      &[role="menubar"] {
        bottom: 18px !important;
        left: 0 !important;
        right: auto !important;
        >.gm-style-mtc {
          float: none !important;
          width: 50px;
          height: 50px !important;
          position: relative;
          margin-top: 5px;
          button {
            font-size: 10px !important;
            padding: 25px 0 0 0 !important;
            background-size: cover !important;
            border: 3px solid white !important;
            border-radius: 9px !important;
            width: 50px;
            height: 50px !important;
            color: white !important;
            text-shadow: 1px 1px 2px rgba(0,0,0,0.6);
            box-shadow: rgb(0 0 0 / 42%) 0 1px 4px 1px;
            + ul {
              left: calc(100% + 0.5rem) !important;
              top: 50% !important;
              transform: translateY(-50%);
              right: auto !important;
              li { font-size: 12px !important; }
              label { margin-left: 4px; }
            }
            &[aria-label="Show street map"] {
              background: white url('../../assets/img/google-map/ic_default-2x.png') center center !important;
              background-size: 110% !important;
            }
            &[aria-label="Show satellite imagery"] {
              background: white url('../../assets/img/google-map/ic_satellite-2x.png') center center !important;
              background-size: 110% !important;
            }
            &[aria-checked="true"] {
              border-color: $primary !important;
            }
          }
        }
      }
      .gm-svpc {
        border-radius: 4px !important;
        top: 20px !important;
      }
    }
  }
}

.map-tooltip {
  .title {
    font-weight: 700;
  }
}

.btn-group-inflight {
  i { font-size: 18px;}
  .btn:first-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .btn {
    height: 46px;
    box-shadow: none !important;
    padding: 9px 20px 8px 20px;
  }
}

.real-time-switch {
  i {
    font-size: 20px;
  }
  .text {
    line-height: 1.2;
    font-size: 10px;
    font-weight: normal;
  }
}

.inflight-section {
  &--timer {
    margin-bottom: 0;
  }
  &--drone-reg {
    position: relative;
    .no-reg-error {
      position: absolute;
      top:0;
      left:0;
      right:0;
      bottom:0;
      background: $color-charcoal1;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
  &--location-dropdown {
    .dropdown-menu {
      min-width: 300px;
    }
  }
}

.timer-block-wrapper {
  padding: 4px;
  min-width: 160px;
  border-radius: $input-border-radius;
  background: $input-bg;
  &.timer-active { @include fz-gradient;}
  .timer-block {
    border-radius: $border-radius-sm;
    text-align: center;
    background-color: $body-bg;
    padding: 4px 6px;
    font-size: 20px;
    position: relative;
    font-weight: lighter;
    letter-spacing: 1.5px;
    &:after {
      display: block;
      position: absolute;
      right: 1px;
      bottom: 1px;
      font-size: 8px;
      line-height: 1;
      &--hours:after { content: "h"; }
      &--minutes:after { content: "m"; }
      &--seconds:after { content: "s"; }
    }
  }
}

.list-group-titles {
  padding-left: $list-group-item-padding-x;
  padding-right: $list-group-item-padding-x;
  font-size: 12px;
  opacity: 0.7;
  margin-bottom: 0.5rem;
}

.list-group {
  &-inline {
    flex-direction: row;
    flex-wrap: wrap;
    position: relative;
  }
  .file-thumb, .file-icon {
    position: relative;
    cursor: pointer;
    .action-icon {
      position: absolute;
      top: -5px;
      right: -9px;
      background: black;
      border-radius: 120px;
      padding: 5px;
    }
    img { height: 120px;}
  }
  .file-icon {
    height: 30px;
    width: 30px;
    background: white;
    border-radius: 3px;
    text-align: center;
    i {
      font-size: 18px;
      line-height: 30px;
    }
    &.pdf-icon { color: #ff0000; }
    &.other-icon { color: $color-charcoal1; }
    &.image-icon { color: $color-green; }
  }
  &-numbered>li {
    padding-left: 2rem;
    &::before {
      position: absolute;
      left: $list-group-item-padding-x;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  &-dark {
    .list-group-item {
      background: $color-charcoal1;
    }
  }
  &-compact {
    .list-group-item {
      padding: -.3rem 0.5rem;
    }
  }
  &-item {
    display: flex;
    flex-direction: column;
    .form-check { margin-bottom: 0; }
    margin-bottom: 0.5rem;
    border-radius: $list-group-border-radius;
    &.highlight-on-hover {
      transition: all 0.2s ease-in-out;
      &:hover {
        background: $color-white-glass-10;
      }
      .show-on-hover { opacity: 1; }
    }
    &.list-group-item-link:hover {
      cursor: pointer;
    }
    @media (hover: hover) {
      .show-on-hover {
        opacity: 0.5;
      }
    }
  }
  > a {
    color: white;
    transition: all 0.2s ease-in-out;
    &:hover {
      color: white;
      background: $color-white-glass-30;
      text-decoration: none;
    }
  }
  .li-row-link {
    border-radius: 5px;
    padding: 3px 6px;
    margin: -3px -6px;
    color: white;
    display: block;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    &:hover {
      background: $color-white-glass-10;
      text-decoration: none;
    }
  }
  .btn-list-item {
    font-size: 11px;
    padding: 4px 7px;
    margin: -3px 0;
    border-radius: 5px;
  }
}

.alert {
  margin-bottom: 1rem;
  border-radius: $border-radius-sm;
  &-warning { @include alert-box-style($warning, $alert-bg-warning); }
  &-info { @include alert-box-style($info, $alert-bg-info); }
  &-info-dark {
    background: rgba(255,255,255,0.03);
    border: none;
    .alert--icon { color: $primary; }
  }
  &-danger { @include alert-box-style($danger, $alert-bg-danger); }
  &-success { @include alert-box-style($success, $alert-bg-success); }
  &-sm {
    font-size: 12px;
    padding: 0.4rem 0.6rem;
    margin-bottom: 0.75rem;
  }
}

.prompt-box {
  background: rgba(255,255,255,0.03);
  border: 1px solid rgba(255,255,255,0.1);
  padding: 1rem;
  border-radius: $border-radius-sm;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  &--gradient-highlight {
    @include fz-gradient;
    border-color: white;
    .prompt-box--icon {
      color: white;
    }
  }
  &--compact {
    padding: 0.5rem;
    min-height: 50px;
    .prompt-box--icon {
      font-size: 18px;
    }
  }
  &--icon {
    font-size: 24px;
    color: $primary;
    line-height: 1;
    @include media-breakpoint-up(lg) {
      font-size: 32px;
    }
  }
  &--heading {
    font-weight: 600;
    font-size: 1rem;
    @include media-breakpoint-up(lg) {
      font-size: 1.2rem;
    }
  }
  &--upload-icon {
    @include fz-gradient;
    width: 21px;
    text-align: center;
    align-items: center;
    justify-content: center;
    display: flex;
    border-radius: 0.2rem;
    margin-right: 0.8rem;
  }
}

.flight-folio-flights {
  .info-item {
    min-width: 100px;
  }
}

.page-title-wrap {
  margin-top: 1rem;
  margin-bottom: $spacer;
  align-items: center;
  .page-title { margin: 0;}
  .page-title-actions {
    &.keep-size { min-height: 32px;}
    position: relative;
    z-index: 1;
    @include media-breakpoint-up(lg) {
      .btn { min-width: 100px; }
    }
    .btn + .btn, .generic-back-btn + .btn { margin-left: 0.75rem;}
  }
}

.page-title {
  margin-top: $grid-gutter-width;
  margin-bottom: $spacer;
  font-size: $h4-font-size;
  font-weight: 600;
  &-secondary {
    opacity: 0.75;
    font-weight: 500;
    font-size: 1rem;
    margin: $spacer 0;
    + p, em { opacity: 0.75}
  }
  &.underline {
    padding-bottom: 3px;
    border-bottom: 2px solid $color-white-glass-20
  }
  &:not(.not-caps) { text-transform: capitalize; }
}

.generic-back-btn {
  vertical-align: middle;
  display: inline-block;
  padding: 4px;
  i {
    line-height: 1.2rem;
    display: inline-block;
    transition: all 0.2s ease-in-out;
    color: $primary;
  }
  .text {
    color: white;
    font-size: 1rem;
    margin-left: 0.5rem;
    font-weight: 600;
  }
  &:hover, &:active {
    text-decoration: none !important;
    i, span { text-decoration: none; }
    i { transform: translateX(-3px)}
  }
}

.general-page-options {
  .dropdown-menu {
    min-width: 250px;
  }
}

.breadcrumbs-wrap {
  transition: all $main-nav-animation-speed ease-in-out;
  ol.breadcrumb {
    border-bottom: 2px solid $color-white-glass-10;
    padding: 0.6rem 0;
    height: $breadcrumb-container-height;
  }
  margin-top: calc(#{$header-height-mobile} - #{$grid-gutter-width});
  padding-bottom: $breadcrumb-spacer;
  .breadcrumb-item {
    text-transform: capitalize;
    &:before { display: none; }
    a:empty:before {
      content: 'Untitled';
    }
    span {
      background: white;
    }
    span, a {
      border-radius: 7px;
      display: inline-block;
      font-size: 12px;
      padding: 3px 7px;
    }
    a {
      color: white;
      background: $color-white-glass-10;
      transition: all 0.2s ease-in-out;
      &:hover {
        color: white;
        text-decoration: none;
        background: $primary;
      }
    }
  }
  @include media-breakpoint-up(lg) {
    margin-top: calc(#{$header-height} - #{$grid-gutter-width});
  }
}

.header-menu {
  display: flex;
  position: fixed;
  top: 6px;
  right: 1rem;
  z-index: $z-index-user-menu;
  .dropdown-toggle {
    height: 46px;
    >i { font-size: 22px; }
  }
  @include media-breakpoint-up(lg) {
    top: 1rem;
    right: $grid-gutter-width;
  }
}

.subnav {
  text-transform: capitalize;
  a, .subnav-link {
    @include subnav-link;
    &.active {
      @include subnav-link-active;
    }
  }
  .disabled { opacity: 0.5; }
  &-button-spacer { padding-right: 90px; }
  .row-completion-status__badge {
    position: relative;
    top: auto;
    transform: none;
    right: auto;
    width: 16px;
    height: 16px;
    display: inline-flex;
    i {
      font-size: 10px;
      line-height: 0;
      margin: auto;
    }
  }
}

.pull-up-to-subnav {
  margin-top: -3rem;
}

.dropdown.open {
  .btn-white-glass-10,
  .btn-outline-white {
    border-color: white;
  }
  .btn-outline-white {
    background: $color-white-glass-30;
  }
}

.dropdown-toggle {
  position: relative;
  padding: 7px 2.5rem 7px 0.7rem;
  display: flex;
  align-items: center;
  text-align: left;
  font-weight: 400;
  height: $input-height;
  >span { vertical-align: middle; }
  >i {
    font-size: 18px;
    vertical-align: middle;
  }
  &::after {
    border: none;
    position: absolute;
    top: 50%;
    right: 0.7rem;
    transform: translateY(-50%);
    @include bi-font("\f282");
    vertical-align: middle;
  }
  &--no-arrow {
    &::after { display: none; }
    padding-right: 0.7rem;
  }
}

.dropdown-menu {
  min-width: 300px;
  max-width: 330px;
  margin-top: $dropdown-padding-x;
  .dropdown-item {
    border-radius: $border-radius-sm;
    cursor: pointer;
    white-space: normal;
    i { font-size: 1.25rem; }
    &:hover { text-decoration: none; }
    &:not(.disabled, .active, .dropdown-item--box):hover {
      background: $color-black-glass-10;
      color: black;
    }
    >.row {
      align-items: center;
      flex-wrap: nowrap;
    }
    &.disabled {
      opacity: 0.6;
      cursor: not-allowed;
    }
  }
  li + li .dropdown-item { margin-top: $dropdown-padding-x}
}

.dropdown--notifications {
  &.open.show::before {
    position: absolute;
    content: '';
    display: block;
    border-style:solid;
    border-width: 10px;
    border-color: transparent transparent #464646 transparent ;
    top: 38px;
    right: 50%;
    margin-right: -10px;
    z-index: $zindex-dropdown + 10;
  }
  .dropdown-menu {
    position: fixed;
    right: 22px !important;
    top: 73px !important;
    background: $color-charcoal2;
    padding: 0 0 1.5rem 0;
    box-shadow: 0 16px 32px rgba(0, 0, 0, 0.4);
    border-radius: 10px;
    max-height: calc(100vh - 100px);
    overflow: auto;
    margin-top: 0;
    .dropdown-header {
      color: white;
      font-size: 14px;
    }
    .notification-icon {
      color: $primary;
      width: 32px;
      margin-top: -5px;
      i {font-size: 28px;}
    }
    .notifications-header {
      line-height: 1;
    }
    li + li > .dropdown-item {
      border-top: 1px solid $color-white-glass-10;
      margin-top: 0;
      padding: 0.6rem 1rem 0.5rem 1rem;
    }
    li:last-child {
      border-bottom: 1px solid $color-white-glass-10;
    }
    .dropdown-item {
      >.row {
        margin-left: 0;
        margin-right: 0;
      }
      position: relative;
      margin: 0;
      cursor: auto;
      border-radius: 0;
      background: $color-black-glass-15;
      color: white ;
      transition: all 0.2s ease-in-out;
      &.is-unread {
        color: white;
        background: $color-white-glass-05 !important;
        cursor: pointer;
        &:hover {
          background: $color-white-glass-10 !important;
          color: white !important;
        }
      }
      &:hover {
        color: white !important;
        background: $color-black-glass-15 !important;
      }

      &.dropdown-empty {
        opacity: 0.6;
      }
      &.disabled {
        background: rgba(0,0,0,0.7);
        opacity: 0.4;
      }
      .notification-action {
        position: absolute;
        top: 6px;
        right: 6px;
      }
    }
  }
}

.gear-image-preview {
  border-radius: $border-radius-sm;
  box-shadow: 0 0 3px 2px rgba(0, 0, 0, 0.125);
  display: inline-block;
  width: 200px;
  height: 200px;
  overflow: hidden;
  position: relative;
  img { max-width: 100%; }
  background: $color-black-glass-15;
  &.show-placeholder {
    background: $color-black-glass-15 url('../../assets/img/image-placeholder.svg') center center no-repeat;
    background-size: 80%;
  }
  &.edit-on-hover {
    cursor: pointer;
    &::after {
      transition: all 0.2s ease-in-out;
      content: 'Upload image';
      position: absolute;
      bottom: 0;
      left:0;
      right:0;
      opacity: 0;
      background: rgba(0,0,0,0.7);
      color: white;
      text-align: center;
      padding: math.div($spacer, 2);
    }
    &:hover {
      &::after { opacity: 1; }
    }
  }
}

.pdf-preview-wrapper {
  width: 100%;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  pdf-viewer {
    display: block;
    padding-bottom: 143%;
    height: 0;
    position: relative;
    .pdfViewer.removePageBorders .page {
      margin: 0 !important;
      max-width: 100%;
    }
    .canvasWrapper {
      max-width: 100%;
    }
  }
}

.img-preview {
  display: inline-block;
  border: 1px solid $gray-200;

  img {
    display: block;
    max-width: 100%;
    max-height: 500px;
  }
}

bo-upload .alert {
  border: none;
  padding: 0;
  background: transparent;
  font-size: 12px;
  margin-bottom: 0;
}

.info-item {
  min-width: 150px;
  &__label {
    font-size: $font-size-sm;
    opacity: 0.7;
    &:not(.no-caps) {text-transform: capitalize;}
  }
  &__text { white-space: normal; }
  &--boxed {
    padding: math.div($card-spacer-y, 1.8) math.div($card-spacer-x, 1.5);
    border-radius: $border-radius-sm;
    background: $color-white-glass-05;
    &[href] {
      display: block;
      border: 1px solid fade-out($primary, 0.5);
      .info-item__label { display: block}
      cursor: pointer;
      color: white;
      transition: all 0.2s ease-in-out;
      &:hover {
        text-decoration: none;
        color: $primary;
        border: 1px solid $primary
      }
    }
  }
  &--icon { font-size: 26px; }
  &--row { margin-top: -5px; }
  + .info-item, + .format-indicator {  margin-top: 1rem; }
}

.info-item-list-wrap {
  &.mw-bigger { min-width: 280px; }
  .info-item {
    position: relative;
    padding: math.div($card-spacer-y, 1.5) math.div($card-spacer-x, 1.5);
    .info-item-list-wrap {
      .info-item {
        padding: math.div($card-spacer-y, 2) 0;
      }
    }
    &--boxed {
      &:not(:last-child) {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
    + .info-item {
      margin-top: 0;
      &:before {
        display: block;
        content: '';
        position: absolute;
        height: 1px;
        left: 0.5rem;
        right: 0.5rem;
        top:0;
        background: $color-white-glass-30;
      }
      &--boxed {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
    }
  }
}

.coordinate {
  &-toggle {
    i, .bi {
      cursor: pointer;
      font-size: 10px;
      margin-left: 10px;
      &:hover {color: $primary;}
    }
  }
}
.modal {
  // add backdrop here to support stacking modals
  background: rgba(0,0,0,0.75);
}

.modal-dialog {
  min-height: calc(90% - 3.5rem);
  display: flex;
  align-items: center;
  .modal-title .smaller { font-size: 75%; }
  &.modal-fullscreen {
    .modal-content {
      > * { display: block; min-height: 100%;} // app-pdf-preview
      .modal-dark {
        min-height: 100%;
        border-radius: 0;
        .modal-body {
          padding: 0;
          height: calc(100vh - 67px);
          display: flex;
          > pdf-viewer {
            height: calc(100vh - 67px);
            >div {
              scrollbar-gutter: stable both-edges;
            }
          }
        }
      }
    }
    .pdf-preview-loader {
      position: fixed;
      display: flex;
      background: $body-bg;
      width: 100%;
      height: calc(100vh - 83px);
      .progressbar {
        margin: auto;
        width: 60%;
      }
    }
    .img-alt-preview {
      margin: auto;
      max-width: 800px;
      img { max-height: 85vh; }
    }
    .pdf-settings {
      padding: 1rem;
      min-width: 280px;
      border-right: 1px solid rgba(255,255,255,0.1);
      &.image-view { min-width: auto;}
      .file-type-display {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background: $primary;
        i { font-size: 1.2rem;}
      }
    }
  }
}

.modal-content {
  border-radius: calc(#{$border-radius-sm} + 5px);
}

.modal-dark {
  border-radius: $border-radius-sm;
  background: $body-bg;
  color: white;
  .modal-title {
    line-height: 1.3;
    small.text-muted {
      font-size: 0.75em;
      display: inline-block;
    }
  }
  .modal-footer, .modal-header {
    border-color: $color-white-glass-10;
  }
}

.modal-footer {
  justify-content: center;
  @include media-breakpoint-up(sm) {
    justify-content: flex-end;
  }
  .btn { min-width: 120px;}
}

.badge {
  border-radius: 15px;
  vertical-align: middle;
  min-width: 29px;
  &.badge-lg {
    font-size: $font-size-base;
    padding: 0.5rem 1rem;
  }
  &.badge-sm {
    i { font-size: 10px;}
    display: inline-block;
    font-size: 12px;
    padding: 0.3rem 0.5rem;
    border-radius: 10px
  }
  &.badge-xs {
    font-size: 10px;
    padding: 0.2rem 0.4rem;
  }
  &.bg-secondary {
    background: $color-white-glass-10;
  }
  &.bg-maintenance { background-color: $color-maintenance-event;}
  &.bg-meeting { background-color: $color-meeting-event;}
  &.bg-operation-draft { background: $color-operation-draft; }
  &.bg-operation-published { background: $color-operation-published; }
  &.bg-operation-completed { background: $color-operation-completed; }
  &.bg-operation-aborted { background: $color-operation-aborted; }
  &.bg-delegated-role {
    background-color:$dark;
    border: 1px solid $color-white-glass-70
  }
}

@keyframes rotating {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.page-loading {
  position: fixed;
  width: 50px;
  height: 50px;
  background: $color-white-glass-10;
  border-radius: 50%;
  left: calc(50% + #{math.div($main-nav-width, 2)});
  transform: translateX(-50%);
  top: 140px;
  z-index: 100000;
  display: flex;
  .spinning-loader {
    font-size: 30px;
  }
}

.spinning-loader {
  animation: rotating 1s linear infinite;
  transform-origin: center;
  margin: auto;
}

.error-message {
  max-width: 700px;
  text-align: center;
  &__code {
    font-size: 36px;
    font-weight: 700;
  }
  &__title {
    font-size: 22px;
    margin-bottom: 1rem;
  }
  &__body { max-width: 500px }
  &__image { margin-bottom: 3rem; }
  &__contact {
    font-size: 12px;
    max-width: 400px;
  }
  @include media-breakpoint-up(lg) {
    &__code { font-size: 46px; }
    &__title { font-size: 26px; }
    &__body { font-size: 1rem; }
  }
}

.status-label {
  border-radius: 16px;
  min-width: 40px;
  padding: 4px 12px;
  display: inline-flex;
  align-items: center;
  height: 26px;
  border: none;
  i {
    font-size: 16px;
    display: inline-block;
    margin: -3px 0;
  }
  .expand-on-hover & {
    .label-text { display: none; }
    &:hover .label-text { display: block; }
  }
}

.report-status-in-progress {
  background: $color-white-glass-10;
  .loading-bar {
    vertical-align: middle;
    height: 4px;
    width: 100px;
    background: $color-black-glass-10;
    position: relative;
    display: inline-block;
    margin-left: 12px;
    overflow: hidden;
    &::before {
      display: block;
      content: '';
      position: absolute;
      height: 4px;
      @include fz-gradient;
      width: 25px;
      left:0;
      animation: ping-pong 1s linear infinite;
    }
  }
}

.tut-nav {
  @include reset-accordion-style();
  .panel-group {
    > .panel {
      .panel-default {
        .panel-title {
          .btn {
            margin-bottom: 1rem;
            padding: 1.3rem;
            max-height: 70px;
            display: flex;
            align-items: center;
            color: white;
            width: 100%;
            text-align: left;
            border-radius: $border-radius-sm;
            --colorMango: #464646FF; --colorPeach: #464646FF;
            background: $color-mango linear-gradient(264deg, var(--colorMango), var(--colorPeach));
            transition: --colorMango .2s, --colorPeach .2s ease-in-out;
            box-shadow: none;
            &::after {
              @include bi-font('\F282');
              font-size: 1.2rem;
              transition: all 0.2s ease-in-out;
              margin-left: auto;
            }
            &:hover, &:active, &:focus {
              --colorMango: $color-peach;
              --colorPeach: $color-mango;
            }
          }
        }
      }
      &.panel-open {
        .panel-default .panel-title .btn {
          --colorMango: $color-peach;
          --colorPeach: $color-mango;
          &::after {
            @include bi-font('\F286');
          }
        }
      }
    }
    .panel-collapse {
      padding-bottom: .6rem;
      a {
        color: white;
        opacity: 0.8;
        display: block;
        padding-bottom: 8px;
        line-height: 1.2;
        transition: all 0.2s ease-in-out;
        &:hover {
          color: white;
          opacity: 1;
          text-decoration: none;
        }
        &.active {
          color: $primary;
          opacity: 1;
          text-decoration: none;
          --colorMango: $color-peach;
          --colorPeach: $color-mango;
        }
      }
    }
  }
}

.tutorialSlider {
  .slide-text {
    margin-bottom: 1rem;
    min-height: 42px;
    @include media-breakpoint-down(xl) {
      p br { display: none; }
    }
  }
  .card {
    border-color: $color-white-glass-10;
    background: #393939;
  }
  &.swiper-container {
    min-height: calc(100vh - 222px);
    .swiper-slide {
      padding-left: 40px;
      padding-right: 40px;
    }
    .swiper-button-prev,
    .swiper-button-next {
      color: $primary;
    }
    .swiper-button-prev {
      left: 0;
    }
    .swiper-button-next {
      right: 0;
    }
  }
}

@keyframes ping-pong {
  0% { left: -25px}
  100% { left: 100px}
}

.centered-tour-element {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.incident-management {
  .card--operations {
    @include calcPageHeight($operations-sub-subnav-height);
  }
  .pt-custom { padding-top: 10px;}
  &.has-review-block {
    .card--operations {
      @include calcPageHeight($operations-sub-subnav-height+68);
    }
  }
}

@import "print";
