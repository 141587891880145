.iti {
  display: block;
  &--allow-dropdown {
    .form-control {
      padding-top: $form-floating-input-padding-t;
      padding-bottom: $form-floating-input-padding-b;
    }
    .iti__flag-container:hover .iti__selected-flag {
      background-color: transparent;
    }
  }
  &__selected-flag.dropdown-toggle {
    padding-right: 0.7rem;
    padding-top: $form-floating-input-padding-t;
    padding-bottom: $form-floating-input-padding-b;
    height: 55px;
  }
  &__arrow {
    border-top-color: $color-white-glass-50;
  }
  &__country-list {
    overflow-x: hidden;
    font-size: $font-size-base;
  }
}

#country-search-box {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-size: $input-font-size;
  &:focus {
    outline: none;
  }
}

.int-tel-field {
  position: relative;
  min-width: 200px;

  &.form-floating-sm {
    .form-control {
      padding-left: 52px !important;
    }
    .iti__selected-flag.dropdown-toggle {
      padding-top: 20px;
      padding-bottom: 6px;
      height: $input-height;
    }
  }
  // fix bug on field loading as untouched but dirty
  > .ng-untouched.ng-dirty {
    .form-control { border-color: $input-border-color;}
    ~ app-field-errors .invalid-feedback {
      display: none;
    }
  }
  > .ng-touched.ng-dirty ~ app-field-errors .invalid-feedback {
    //.form-control { border-color: !important;}
    display: block;
  }
}


