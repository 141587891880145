@use "sass:math";
@import "flyte_vars";
@import "flyte_mixins";

.main-content {
  min-height: 100vh;
  padding: $main-content-padding;
  transition: all $main-nav-animation-speed ease-in-out;
  @include media-breakpoint-up(md) {
    padding: $grid-gutter-width;
  }
  @include media-breakpoint-up(lg) {
    margin-left: $main-nav-width;
  }
}

.limit-view-width {
  max-width: 1000px;
}

.read-only-indicator {
  position: fixed;
  top: 23px;
  right: 310px;
  z-index: 21;
  font-size: 12px;
  .alert  {
    background: none;
    border: none
  }
}

.page-header-row {
  display: flex;
  background: $body-bg;
  border-bottom: 2px solid $color-white-glass-10;
  height: $header-height-mobile;
  padding-right: 100px;
  padding-left: 24px;
  margin-right: $grid-gutter-width;
  align-items: center;
  position: fixed;
  left: $grid-gutter-width;
  right:0;
  width: 100%;
  top:0;
  z-index: $z-index-header;
  transition: all $main-nav-animation-speed ease-in-out;
  .page-header {
    font-size: $h4-font-size;
    font-weight: 600;
  }
  .btn { min-width: 150px;}
  &--duo {
    flex-direction: column;
    align-items: start;
    justify-content: center;
    .page-header {
      width: 100%;
      &__main-title {
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        min-height: 18px;
      }
      &__subtitle {
        font-size: 20px;
        line-height: 20px;
        min-height: 20px;
      }
    }
  }
  @include media-breakpoint-up(lg) {
    height: $header-height;
    padding-left: 0;
    left: calc(#{$main-nav-width} + #{$grid-gutter-width});
    .page-header { font-size: $h4-font-size; }
    &--duo {
      .page-header {
        &__main-title {
          font-size: 16px;
          line-height: 30px;
          min-height: 30px;
        }
        &__subtitle {
          font-size: 22px;
          line-height: 30px;
          min-height: 30px;
        }
      }
    }
  }
}

.fixed-bottom-nav {
  position: fixed;
  z-index: $z-index-bottom-nav;
  bottom:0;
  left: $main-nav-width;
  right:0;
  background: $color-charcoal2;
  padding: 1rem;
  height: $fixed-bottom-navbar-height;
}



