@import "_flyte_vars";

.onboard-base-wrapper {
  background: white;
  min-height: 100vh;

  @include light-theme;
  p {
    color: $color-charcoal3;
    max-width: 550px;
  }
  .onboarding-intro {
    color: $color-charcoal3;
    img {
      margin-bottom: 1rem;
      width: 150px;
    }
    h2 { display: none; }
    @include media-breakpoint-up(sm) {
      img { width: 200px };
    }
    @include media-breakpoint-up(md) {
      padding-left: 70px;
      img { width: 283px };
      h2 {
        display: block;
        margin-bottom: 1rem;
        font-size: 32px;
        font-weight: 600;
      }
      p {
        font-size: 1rem;
        max-width: 250px;
      }
    }
    @include media-breakpoint-up(lg) {
      padding-left: 100px;
    }
  }
  .card--onboarding {
    border: 2px solid  $color-black-glass-10;
    background: rgba(0,0,0,0.03);
    border-radius: $border-radius;
    max-width: 550px;
    >.card-body {
      @include media-breakpoint-up(md){
        padding: 1.5rem;
      }
      @include media-breakpoint-up(xl){
        padding: 2.5rem;
      }
    }
  }
  .card-title {
    font-weight: 700;
    color: $primary;
    font-style: italic;
    font-size: 20px;
    margin-top: 0;
  }
  .content-column {
    border: 1px solid red;
    max-width: 620px;
    width: 100%;
    display: flex;
    flex-direction: column;
    z-index: 1;
    margin: $grid-gutter-width auto;
    @include media-breakpoint-up(sm) {
      margin-top: $grid-gutter-width;
      margin-bottom: $grid-gutter-width*2;
    }
    @include media-breakpoint-up(xxl){
      margin-left: 480px;
    }
  }
  .onboard-heading {
    color: $color-charcoal1;
    text-transform: uppercase;
    margin-bottom: 1rem;
    font: {
      size: 24px;
      style: italic;
      weight: 700;
    }
  }
  .onboard-buttons {
    justify-content: flex-end;
    margin-top: 2rem;
    .btn { min-width: 130px;}
    .btn-link {
      min-width: auto;
      &:hover, &:focus { background: $color-black-glass-10; }
    }
  }
  form {
    margin-top: 1rem;
  }
  @include media-breakpoint-up(md) {
    .container-fluid { padding-right: 2rem;}
    .left-bar {
      height: 100vh;
      @include fz-gradient();
      background: white;
      position: fixed;
      top: 0;
      left: 0;
      bottom: 0;
      width: 40px;
    }
    .drone-controller {
      margin-top: auto;
      margin-left: -1rem;
      margin-bottom: -1rem;
      width: 380px;
      height: 530px;
      position: relative;
      background: url('../../assets/img/onboarding/circle.svg') 26px -23px no-repeat;
      background-size: 350px 350px;
      &::after {
        display: block;
        width: 350px;
        height: 470px;
        position: absolute;
        bottom: 0;
        left: 0;
        content: '';
        background: url('../../assets/img/onboarding/drone-controller.png') no-repeat;
        background-size: contain;
      }
    }
    .intro-wrapper {
      display: flex;
      flex-direction: column;
      min-height: 100%;
    }
    .onboard-heading {
      font-size: 32px;
      margin-top: 10px;
      margin-bottom: 2rem;
    }
    .card-title {
      font-size: 24px;
    }
  }
  @include media-breakpoint-up(lg) {
    .drone-controller {
      width: 475px;
      height: 700px;
      background-size: auto;
      background-position:  24px -58px;
      &::after {
        width: 485px;
        height: 652px;
      }
    }
  }
}
.onboarding-steps {
  display: flex;
  width: 60%;
  margin-bottom: 0.5rem;
  .step {
    height: 5px;
    border-radius: 3px;
    background: $primary;
    opacity: 0.5;
    width: 21%;
    margin-right: 3%;
    transition: all 0.2s ease-in-out;
    &.active {
      width: 43%;
      opacity: 1;
    }
  }
}

// Free Trial Modal Styling
.free-trial-modal {
  .modal-content {
    border: 3px solid #B7B7B7;
    font-size: 1rem;
    &::before {
      content: '';
      display: block;
      background: url('../../assets/img/free_trial_badge_light.svg') no-repeat center;
      position: absolute;
      top: 10px;
      left: 10px;
      width: 65px;
      height: 65px;
      background-size: contain;
    }
    @include media-breakpoint-up(md) {
      &::before {
        top: -40px;
        left: -50px;
        width: 120px;
        height: 120px;
      }
    }
  }
  .btn-close-modal {
    cursor: pointer;
    i {
      color: #9E9E9E;
      font-size: 30px;
    }
  }
}


