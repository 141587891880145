$avatar-size-xs: 24px !default;
$avatar-size-sm: 32px !default;
$avatar-size-md: 46px !default;
$avatar-size-lg: 64px !default;
$avatar-size-xl: 120px !default;
$avatar-size-xxl: 176px !default;


$avatar-font-family: 'Open Sans', sans-serif !default;
$avatar-font-weight: 400 !default;
$avatar-bg-default: #fff !default;
$avatar-bg-primary: #007eff !default;
$avatar-bg-secondary: #62aefd !default;
$avatar-border-color: rgba(0,0,0,0.1) !default;
$avatar-border-radius-custom: 15% !default;
$avatar-icon-bg: transparent !default;
$avatar-icon-color: rgba(255,255,255,1) !default;
$avatar-icon-hover-color: white !default;
$avatar-initials-color: white !default;
$avatar-initials-size-ratio: 2.2 !default;
$avatar-icon-size-ratio: 2 !default;
$avatar-spacing: 10px !default;
$avatar-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.125) !default;
$avatar-text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.125) !default;

$avatar-bg-letter-colors: (
  a: #AA47BC,
  b: #7A1FA2,
  c: #78909C,
  d: #465A65,
  e: #EC407A,
  f: #C2175B,
  g: #5C6BC0,
  h: #0288D1,
  i: #00579C,
  j: #0098A6,
  k: #00887A,
  l: #004C3F,
  m: #689F39,
  n: #33691E,
  o: #8C6E63,
  p: #5D4038,
  q: #7E57C2,
  r: #512DA7,
  s: #EF6C00,
  t: #F5511E,
  u: #BF360C,
  v: #17BEE2,
  w: #0C809A,
  x: #E86464,
  y: #BF5555,
  z: #EBBB14
)
