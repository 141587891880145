@use "sass:math";
@import "flyte_vars";

.form-small-wrapper {
  max-width: 600px;
}
$form-check-yn-width: 90px;
$form-check-yn-height: 32px;

$form-check-width: 40px;
$form-check-height: 22px;
.form-check {
  padding-left: 1.8rem;
  position: relative;
  margin-bottom: 0.5rem;
  bo-form-field-errors {
    flex-basis: 100%;
  }
  .form-check-label {
    margin-bottom: 0;
    line-height: 1.2;
    color: $body-color;
  }
  &.form-check-inline { display: inline-flex; }
  .form-check-input {
    width: 18px;
    min-width: 18px;
    height: 18px;
    margin-left: -1.8rem;
    margin-top: 0;
    &:focus { box-shadow: none; }
    &.is-valid {
      ~ .form-check-label {
        color: $body-color;
      }
      &:focus { box-shadow: none; }
      &:checked {
        background-color: $primary;
        border-color: $primary;
      }
    }
    &.is-invalid {
      &.form-check-input { border-color: $danger; }
    }
    &:not(:checked) {
      background: transparent;
      border: 1px solid $color-white-glass-30;
    }
  }
  .invalid-feedback { margin-top: 0; }
  &-right {
    label { display: block; }
    padding-left: 0 !important;
    padding-right: 2.5rem;
    .form-check-input {
      position: absolute;
      right: 0;
      margin-left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  &-boxed {
    cursor: pointer;
    margin-bottom: 1rem;
    position: relative;
    padding: 0;
    font-size: 1rem;
    label {
      cursor: pointer;
      background: white;
      border-radius: $border-radius;
      color: $color-charcoal3 !important;
      font-weight: 600;
      display: block;
      margin-bottom: 0;
      padding: $card-spacer-x $card-spacer-x $card-spacer-x 3rem;
      &:hover {
        transition: box-shadow 0.2s ease-in-out;
        box-shadow: inset 0 0 0 1px $color-black-glass-15;
      }
    }
    .label-text {
      font-weight: 400;
      margin-left: -1.8rem;
      margin-top: 3px;
      position: relative;
    }
    .form-check-input {
      position: relative;
      margin-left: $card-spacer-x !important;
      margin-top: $card-spacer-x !important;
      z-index: 1;
      &:checked {
        ~ label {
          @include fz-gradient;
          color: white !important;
          box-shadow: none;
        }
      }
    }
  }
  @include media-breakpoint-up(md) {
    &:not(&-boxed) { padding-left: 2rem;}
    .form-check-input {
      width: 22px;
      min-width: 22px;
      height: 22px;
      margin-left: -2rem;
    }
    &-boxed {
      font-size: 1.1rem;
    }
  }
}

$form-check-button-size: 28px;

.form-check-button {
  display: inline-block;
  position: relative;
  margin-top: 5px;
  .form-check-label {
    display: block;
    background: $color-gray-light;
    border-radius: math.div($form-check-button-size, 2);
    width: $form-check-button-size;
    height: $form-check-button-size;
    line-height: $form-check-button-size;
    text-align: center;
    color: $color-gray;
    cursor: pointer;
  }
  .form-check-input {
    margin: 0;
    opacity: 0;
    cursor: pointer;
    position: absolute;
    width: $form-check-button-size;
    height: $form-check-button-size;
    &:checked {
      ~ .form-check-label {
        transition: all 0.2s ease-in-out;
        background: $primary;
        color: white;
      }
    }

  }
  +.form-check-button {
    margin-left: 5px;
  }
}

app-form-field.text-white {
  .form-check > .form-check-label {
    color: white !important;
  }
}
app-form-field.has-error {
  .form-floating .form-control, .form-floating .form-control:focus {
    border-color: $danger;
  }
}

.form-check-switch-yn .form-check,
.form-check-switch-yn.form-check {
  position: relative;
  padding-left: 0 !important;
  height: $form-check-yn-height;
  margin-bottom: 0;
  &::before, &::after {
    display: block;
    content: '';
    position: absolute;
    transition: right 0.1s ease-in-out;
    line-height: 30px;
    text-align: center;
    &:before { content: ''; }
  }
  .form-check-label {
    cursor: pointer;
    height: $form-check-yn-height;
    display: flex;
    align-items: center;
    margin-left: 0;
    padding-right: $form-check-yn-width + 5px;
    width: 100%;
    color: white !important;
    &::before, &::after {
      display: block;
      content: '';
      position: absolute;
      transition: right 0.1s ease-in-out;
      line-height: 30px;
      text-align: center;
    }
    // toggle
    &::after {
      width: math.div($form-check-yn-width, 2.2);
      right: math.div($form-check-yn-width, 2.2) + 5px;
      background: $danger;
      border-radius: 4px;
      content: 'NO';
      top: math.div($form-check-yn-height, 8);
      bottom: math.div($form-check-yn-height, 8);
      line-height: $form-check-yn-height - (math.div($form-check-yn-height, 8)*2);
    }
    // background
    &:before {
      width: $form-check-yn-width;
      background: $color-white-glass-10;
      border-radius: 4px;
      right:0;
      top:0;
      bottom:0;
    }
  }
  .form-check-input {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    float: none;
    margin: 0;
    height: $form-check-yn-height;
    right: 0;
    &:checked ~ .form-check-label {
      &::after {
        right: 5px;
        content: 'YES';
        background: $color-green;
      }
    }
  }
}

.form-check-switch-battery-charge.form-check {
  @extend .form-check-switch-yn;
  .form-check-label::after {
    content: 'V';
    background: $primary;
  }
  .form-check-input {
    &:checked ~ .form-check-label::after {
      content: '%';
      background: $primary;
    }
  }
}

.form-check-switch {
  position: relative;
  padding-left: 0 !important;
  height: $form-check-height;
  margin: -3px -6px -3px 0;
  &::before, &::after {
    display: block;
    content: '';
    position: absolute;
    transition: right 0.1s ease-in-out;
    line-height: 30px;
    text-align: center;
  }
  .form-check-label {
    cursor: pointer;
    height: $form-check-height;
    display: flex;
    align-items: center;
    padding-right: $form-check-width + 5px;
    &::before, &::after {
      display: block;
      content: '';
      position: absolute;
      transition: right 0.1s ease-in-out;
      line-height: 30px;
      text-align: center;
    }
    // toggle
    &::after {
      width: $form-check-height - 8px;
      height: $form-check-height - 8px;
      right: math.div($form-check-width, 2.2) + 4px;
      background: $color-white-glass-50;
      border-radius: 16px;
      content: '';
      top: 4px;
      bottom: 4px;
    }
    // background
    &:before {
      width: $form-check-width;
      background: $color-black-glass-15;
      border-radius: 16px;
      right:0;
      top:0;
      bottom:0;
      transition: all 0.2s ease-in-out;
    }
  }
  .form-check-input {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    float: none;
    margin: 0;
    height: $form-check-height;
    right: 0;
    &:checked ~ .form-check-label {
      &::after {
        right: 5px;
        content: '';
        background: $color-green;
      }
      &::before {
        background: white;
      }
    }
  }
}

label { margin-bottom: 3px; }

.form-control {
  background-image: none !important;
  &.is-valid {
    border-color: $input-border-color;
    &:focus { border-color: $input-focus-border-color; }
  }
  &.is-valid, &.is-invalid {
    &:focus { box-shadow: none !important; }
  }
  &:disabled, &.disabled, &[disabled] {
    cursor: not-allowed !important;
    background-color: fade-out($color-charcoal2, 0.7);
    border-color: $color-white-glass-10;
    color: fade-out($input-color, 0.5);
    ~ label, ~ bo-label>label { opacity: 0.5 !important; }
  }
  &[readonly] {
    border-color: $color-charcoal3 !important;
    background-color: $color-charcoal3;
  }
  // remove arrows from type="number" fields
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  } /* Chrome, Safari, Edge, Opera */
  &[type=number] { -moz-appearance: textfield; } /* Firefox */
}

.field-highlight {
  border: 1px solid $primary !important;
  background: $color-primary-glass-15 !important;
}

.field-tooltip {
  position: absolute;
  top: 12px;
  right: 10px;
  z-index: 1;
}
.form-field-tooltip {
  position: relative;
  .form-floating.form-floating-sm .form-control { padding-right: 42px;}
  i {
    position: absolute;
    right: 0;
    top: 0;
    display: block;
    padding: 0.8rem;
  }
}


.temp-date-field-spacer {
  margin-top: 1.3rem;
}

.form-buttons {
  margin-top: 1rem;
  text-align: end;
  .btn { min-width: 120px;}
  .btn + app-submit .btn,
  .btn + .btn {
    margin-left: 1rem;
  }
}

@mixin field-has-icon-style {
  .form-control {
    text-overflow: ellipsis;
    padding-right: 30px !important;
  }
}

@mixin field-icon-style {
  position: absolute;
  top: 50%;
  transform: translateY(-48%);
  right: 0.75rem;
  cursor: pointer;
  i {
    transition: all 0.2s ease-in-out;
    color: $color-white-glass-50;
    font-size: 1rem;
  }
}

.field-has-tooltip {
  @include field-has-icon-style;
  .tooltip-icon {
    @include field-icon-style;
  }
}

.form-date-field {
  @include field-has-icon-style;
  .date-field-icon {
    @include field-icon-style;
    &.relative-to-top {
      top: 12px;
      transform: none;
    }
  }
  &:hover .date-field-icon:not(.owl-dt-trigger-disabled) {
    i { color: $primary; }
  }
  &.form-floating-sm {
    .form-control {
      ~ label,
      ~ bo-label label {
        padding-right: 35px;
      }
    }
  }

  &.field-has-tooltip {
    .tooltip-icon {
      right: 2.5rem;
    }
    .form-control {
      padding-right: 65px !important;
    }
  }
}

.format-indicator {
  position: relative;
  display: flex;
  app-form-field { flex-basis: 100%; }
  .form-control {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;

    &:focus {
      border-right-color: $input-focus-border-color
    }
  }
  &.has-toggle {
    .form-control {
      border-right: none;
    }
    .format-indicator-btn {
      display: block;
      background-color: $color-white-glass-20;
      border-top-right-radius: $input-border-radius;
      border-bottom-right-radius: $input-border-radius;
      border: 1px solid $color-white-glass-20;
      color: $color-white-glass-70;
      height: 46px;
      min-width: 50px;
      padding: 0 5px;
      transition: all 0.2s ease-in-out;
      &:hover {
        background: $color-white-glass-30
      }
      &:focus {
        border-color: $input-focus-border-color;
      }
    }
  }
  &:not(has-toggle)::after {
    display: block;
    white-space: nowrap;
    background-color: $input-bg;
    border-top-right-radius: $input-border-radius;
    border-bottom-right-radius: $input-border-radius;
    border: 1px solid $input-border-color;
    border-left: none;
    color: $color-white-glass-50;
    height: 100%;
    min-width: 50px;
    width: auto;
    text-align: center;
    line-height: 44px;
    padding-left: 10px;
    padding-right: 10px;
  }
  &__info-item {
    .info-item {
      flex-grow: 1;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    &.has-toggle .format-indicator-btn {
      height: 57px;
      line-height: 54px;
      border-color: transparent;
    }
    &:not(has-toggle)::after {
      line-height: 57px;
      background-color: $color-white-glass-10;
    }
  }

  &.format-month::after { content: 'm' };
  &.format-day::after { content: 'd' };
  &.format-hour::after { content: 'h' };
  &.format-hourmin::after { content: 'h:mm'; font-size: 12px;};
  &.format-min::after { content: 'm' };
  &.format-sec::after { content: 's' }
  &.format-celsius::after { content: '°C' }
  &.format-kg::after { content: 'kg' }
  &.format-km::after { content: 'km' }
  &.format-frequency-ghz::after { content: 'GHz' }
  &.format-frequency-mhz::after { content: 'MHz' }
  &.format-windspeed::after { content: 'm/s'; font-size: 12px; }
  &.format-voltage::after { content: 'v' }
  &.format-capacity::after { content: 'mAh' }
  &.format-percentage::after { content: '%' }
  &.format-feet::after { content: 'ft' }
  &.format-meters::after { content: 'm' }
}

.form-floating {
  min-width: 200px;
  position: relative;
  &.form-floating-shrink {
    min-width: auto;
  }
  &.form-floating-sm {
    .form-control {
      height: $floating-form-height-sm;
      font-size: 14px;
      padding-left: 0.7rem;
      padding-right: 0.8rem;
    }
    label,
    bo-label label {
      padding-top: $input-btn-padding-y;
      padding-bottom: $input-btn-padding-y;
      line-height: $input-line-height;
      color: $color-white-glass-70;
    }
    > .form-control:focus,
    > .form-control:not(:placeholder-shown),
    > .form-select.ng-dirty,
    &.show-placeholder .form-control {
      padding-top: 20px;
      padding-bottom: 6px;
      ~ label,
      ~ bo-label label {
        text-overflow: unset;
        overflow: unset;
        color: $color-white-glass-70;
        transform: $form-floating-label-transform-sm;
      }
    }
    &.show-placeholder {
      label,
      bo-label label {
        text-overflow: unset;
        overflow: unset;
        color: $color-white-glass-70;
        transform: $form-floating-label-transform-sm;
      }
    }
  }
  .form-control,
  >.form-select {
    height: $form-floating-height;
    line-height: $form-floating-line-height;
  }

  label,
  bo-label label {
    position: absolute;
    white-space: nowrap;
    right: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    top: 0;
    left: 0;
    height: 100%; // allow textareas
    padding: $form-floating-padding-y $form-floating-padding-x;
    pointer-events: none;
    border: $input-border-width solid transparent; // Required for aligning label's text with the input as it affects inner box model
    transform-origin: 0 0;
    @include transition($form-floating-transition);
  }

  .form-control {
    padding: $form-floating-padding-y $form-floating-padding-x;
    &:focus,
    &:not(:placeholder-shown) {
      padding-top: $form-floating-input-padding-t;
      padding-bottom: $form-floating-input-padding-b;
    }
    &:focus {
      border-color: $input-focus-border-color;
    }
    // Duplicated because `:-webkit-autofill` invalidates other selectors when grouped
    &:-webkit-autofill {
      box-shadow: inset 0 0 10px 1000px $input-bg !important;
      padding-top: $form-floating-input-padding-t;
      padding-bottom: $form-floating-input-padding-b;
      -webkit-text-fill-color: $input-color;
      ~ label,
      ~ bo-label label {
        opacity: $form-floating-label-opacity;
        transform: $form-floating-label-transform;
      }
    }
    &.ng-invalid.ng-touched { border-color: $danger !important;}
  }

  > .form-select {
    padding-top: $form-floating-input-padding-t;
    padding-bottom: $form-floating-input-padding-b;
    ~ label { transform: none; }
  }

  &.show-placeholder .form-control,
  .form-control:focus,
  .form-control:not(:placeholder-shown),
  > .form-select.ng-dirty {
    padding-top: $form-floating-input-padding-t;
    padding-bottom: $form-floating-input-padding-b;
    ~ label,
    ~ bo-label label {
      text-overflow: unset;
      overflow: unset;
      opacity: $form-floating-label-opacity;
      transform: $form-floating-label-transform;
    }
  }
  &.show-placeholder {
    .form-control::placeholder { color: $input-placeholder-color  }
    label,
    bo-label label {
      text-overflow: unset;
      overflow: unset;
      opacity: $form-floating-label-opacity;
      transform: $form-floating-label-transform;
    }
  }
  textarea.form-control {
    min-height: 150px;
    padding-top: 20px !important;
    &.textarea-inline {
      min-height: 92px;
    }
  }
  &.textarea-small {
    textarea.form-control {
      min-height: 92px;
    }
  }
}

.is-invalid {
  .form-control {
    border-color: $danger;
  }
}

// Service level for special case styling.
form.isSubmitted.ng-invalid,
.isSubmitted {
  .ng-select.ng-invalid .ng-select-container,
  .form-control.ng-invalid {
    border-color: $danger;
  }
  .ng-invalid~.invalid-feedback { display: block; }
}

// detect operation form errors to make space for alert box
form.ng-submitted.ng-invalid {
  .card--operations__body {
    min-height: calc(100vh - 481px);
  }
}

.bo-non-field-errors {
  text-align: left;
  .alert {
    margin-top: 1rem;
    margin-bottom: 0.6rem;
    font-size: 12px;
    padding: 0.2rem 0.6rem;
    color: white;
    &::before { display: inline-block; }
    &.alert-danger {
      background: fade_out($danger, 0.4);
      &::before { @include bi-font("\f33a"); }
    }
    &.alert-warning {
      background: fade_out($warning, 0.4);
      &::before { @include bi-font("\f33a"); }
    }
    &.alert-info {
      background: fade_out($info, 0.4);
      &::before { @include bi-font("\F430"); }
    }
  }
  &.form-placement-top {
    .alert {
      margin-top: 0;
      margin-bottom: 1rem;
    }
  }
}

.invalid-feedback {
  &.offset-top-3 {
    margin-top: -.75rem;
  }
}


.field-no-error-display {
  .invalid-feedback { display: none !important; }
}

.timepicker {
  table {
    border-spacing: 0;
    border-collapse: separate;
    td { padding: 0;}
    .form-group + td { padding-left: 0.5rem; padding-right: 0.5rem; }
  }
  .btn { min-width: auto !important; }
  .btn-link {
    padding: 0.1rem 0.4rem;
    &:hover { background: none; }
  }
  .btn-default {
    padding-left: 0;
    padding-right: 0;
  }
  .bs-timepicker-field { padding: 0.35rem $input-padding-x !important; }
}

.show-password-wrap {
  position: relative;
  .form-control {
    padding-right: 50px;
  }
  i {
    position: absolute;
    top: 9px;
    right: 14px;
    font-size: 20px;
    opacity: 0.8;
    cursor: pointer;
  }
  &.field-lg {
    i {
      top: 13px;
      right: 1rem;
    }
  }
}

.form-multi-input {
  position: relative;
  &::before {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    top: 0;
    height: $input-height;
    border: $input-border-width solid $input-border-color;
    border-radius: $input-border-radius;
    background: $input-bg;
  }
  .form-floating {
    min-width: 0;
    .form-control {
      min-width: 75px;
      width: 100%;
    }
    &::after {
      display: block;
      position: absolute;
      top:50%;
      transform: translateY(-50%);
      z-index: 1;
      color: $color-white-glass-30;
    }
  }
  >.row {
    flex-wrap: nowrap;
    >.col:not(:first-child):not(:last-child) {
      .form-control:not(:focus):not(.ng-invalid.ng-touched) {
        &.ng-pristine, &.ng-valid {
          border-left: none;
          border-right: none;
          border-radius: 0;
        }
      }
    }
    >.col:first-child {
      .form-control:not(:focus):not(.ng-invalid.ng-touched) {
        &.ng-pristine, &.ng-valid {
          border-right: none;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
      }
    }
    >.col:last-child {
      .form-control:not(:focus):not(.ng-invalid.ng-touched) {
        &.ng-pristine, &.ng-valid {
          border-left: none;
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
      }
      .form-floating::after { content: '';}
    }
  }
  + .invalid-feedback--custom { margin-top: -12px; margin-bottom: 0.5rem; }
  &--time .form-floating::after {
    content: ':';
    right: -6px; // row gx-2
  }
  &--dash .form-floating::after {
    content: '-';
    right: -12px; // row gx-3
  }
  &--disabled {
    &::before { background-color: #383838; }
    .form-control {
      background-color: #383838;
      border-color: #4a4a4a;
    }
  }
}

.datatable-wrapper .form-multi-input {
  &::before {
    height: 36px;
  }
}

