@import "../../flyte_vars";
@import "~@danielmoncada/angular-datetime-picker/assets/style/picker.min.css";

.owl-dt-container {
  font-family: $font-family-base;
  border-radius: $input-border-radius;
  box-shadow: $box-shadow;
}

.owl-dt-calendar-table {
  .owl-dt-calendar-cell-selected {
    background-color: $primary;
  }
}

.owl-dt-control-arrow-button {
  svg {
    fill: $primary;
  }
}

.owl-dt-container-disabled,
.owl-dt-trigger-disabled,
.owl-dt-calendar-cell-disabled span {
  cursor: not-allowed !important;
}

.owl-dt-control-arrow-button[disabled] {
  opacity: 0.5;
}

.owl-dt-timer-content {
  .owl-dt-timer-input {
    background-color: $color-black-glass-15;
    border-color: transparent;
    width: 3rem;
    border-radius: $input-border-radius;
    &:focus {
      border-color: $primary;
    }
  }
}

.owl-dt-container-buttons {
  height: auto;
}

.owl-dt-container-control-button {
  background-color: transparent;
  color: $primary;
  padding: $btn-padding-y-sm $btn-padding-x-sm;
  border-radius: $btn-border-radius-sm;

  font-weight: $btn-font-weight;
  font-size: $btn-font-size-sm;
  &:hover {
    background: $color-black-glass-10;
    .owl-dt-control-button-content { background: transparent;}
  }
  +.owl-dt-container-control-button {
    color: white;
    background-color: $primary;
    margin-left: 0.5rem;
    &:hover {
      background: lighten($primary, 5%);
    }

  }
}

.owl-dt-container-row:last-child {
  padding: 0.5rem;
}
