@use "sass:math";

//Colors
$color-mango: #FC4969;
$color-peach: #FB8953;
$color-orange: #FB4B1A;
$color-green: #1EA350;
$color-cyan: #08abce;
$color-brightred: #FF004D;
$color-softred: #fe5e5e;
$color-charcoal1: #313131;
$color-charcoal2: #424242;
$color-charcoal3: #5a5a5a;
$color-gray: #767676;
$color-gray-light: #e5e5e5;
$color-white-glass-02: fade_out(#fff, 0.98);
$color-white-glass-05: fade_out(#fff, 0.95);
$color-white-glass-10: fade_out(#fff, 0.9);
$color-white-glass-15: fade_out(#fff, 0.85);
$color-white-glass-20: fade_out(#fff, 0.8);
$color-white-glass-30: fade_out(#fff, 0.7);
$color-white-glass-40: fade_out(#fff, 0.6);
$color-white-glass-50: fade_out(#fff, 0.5);
$color-white-glass-60: fade_out(#fff, 0.6);
$color-white-glass-70: fade_out(#fff, 0.3);
$color-black-glass-05: fade_out(#000, 0.95);
$color-black-glass-10: fade_out(#000, 0.9);
$color-black-glass-15: fade_out(#000, 0.85);
$color-black-glass-20: fade_out(#000, 0.8);
$color-black-glass-30: fade_out(#000, 0.7);

//Calendar colours
$color-meeting-event: #8062c4;
$color-maintenance-event: #18816e;

//Operation Status colours
$color-operation-draft: $color-cyan;
$color-operation-published: #63ba3c;
$color-operation-aborted: $color-softred;
$color-operation-completed: $color-gray;

//Role colours
$color-delegated-role: #0a3c64;

// Bootstrap

//General
$font-family-base: 'Work Sans', sans-serif;
$font-size-base: .875rem;
$font-size-sm: 0.75rem;
$body-bg: $color-charcoal1;
$body-color: white;
$primary: $color-peach;
$secondary: $color-gray;

$color-primary-glass-15: fade-out($primary, 0.85);

$warning: #de8c3a;
$danger: $color-softred;
$success: $color-green;
$info: $color-cyan;

$light: $color-white-glass-10;
$link-decoration: none;
$border-radius: 12px;
$border-radius-sm: 8px;
$grid-gutter-width:  1.5rem;
$spacer: 1rem;
$hr-margin-y: 2rem;
$hr-color: white;
$hr-opacity: 0.1;
$hr-height: 2px;
$headings-margin-bottom: $spacer;
$headings-font-weight: 700;
$text-muted: fade_out($body-color, 0.6);

//Cards
$card-bg: $color-charcoal2;
$card-hover-bg: rgba(255,255,255,.2);
$card-border-radius: 18px;
$card-cap-bg: transparent;
$card-title-spacer-y: 0;
$card-border-width: 1px;

$card-bg-operations: $color-charcoal3;

//inputs
$input-bg: $color-charcoal2;
$input-border-color: $color-charcoal3;
$input-color: #fff;
$input-height: 46px;
$input-border-radius: $border-radius-sm;
$input-focus-border-color: $color-white-glass-50;
$input-focus-box-shadow: none;
$input-padding-x: 0.75rem;
$input-placeholder-color: $color-white-glass-40;
$form-label-color: rgba(0,0,0,0.4);
$input-disabled-bg: $color-charcoal2;
$input-focus-bg: $color-charcoal2;

//input-btn
$input-btn-padding-y-lg: 0.8rem;
$input-btn-padding-y: 0.70rem;
$input-btn-padding-y-sm: 0.3rem;

$input-btn-padding-x: 1.5rem;
$input-btn-padding-x-sm: 1.2rem;
$input-btn-focus-box-shadow: none;

$form-select-focus-box-shadow: none;
$form-select-border-radius: $input-border-radius;

//floating-form
$form-floating-label-opacity: 1;
$form-floating-label-transform: scale(.85) translateY(-.6rem) translateX(.15rem);
$form-floating-label-transform-sm: scale(0.75) translateY(-7px) translateX(0.1rem);
$floating-form-height-sm: 46px;

//btn
$btn-font-weight: 600;
$btn-font-size: $font-size-base;
$btn-font-size-sm: $font-size-base;
$btn-border-radius: $border-radius-sm;
$btn-font-size-sm: $btn-font-size;

//modals
$modal-content-color: $color-charcoal1;
$modal-content-bg: white;
$zindex-modal-backdrop: 900;
$modal-backdrop-bg: transparent;
$zindex-modal: 900;

//list-group
$list-group-bg: $color-white-glass-05;
$list-group-border-color: transparent;
$list-group-color: white;
$list-group-border-radius: $border-radius-sm;
$list-group-item-padding-x: 0.75rem;

//breadcrumbs
$breadcrumb-margin-bottom: 0;
$breadcrumb-padding-y: 6px;
$breadcrumb-font-size: 0.875rem;
$breadcrumb-divider-color: $color-white-glass-30;
$breadcrumb-active-color: $color-white-glass-50;
$breadcrumb-divider: quote("");
$breadcrumb-active-color: $color-charcoal1;
$breadcrumb-spacer: 1.5rem;
$breadcrumb-container-height: 45px;


//dropdopwn
$dropdown-padding-x: 0.5rem;
$dropdown-item-padding-y: 0.5rem;
$dropdown-item-padding-x: 0.5rem;
$dropdown-link-hover-bg: transprent;
$dropdown-divider-margin-y: $dropdown-padding-x;
$dropdown-border-radius: $btn-border-radius;
$dropdown-link-color: $color-charcoal1;
$dropdown-link-active-color: $color-charcoal1;
$dropdown-link-active-bg: fade_out($primary, 0.5);
$dropdown-header-color: white;

//Pagination
$pagination-bg: transparent;
$pagination-border-color: none;
$pagination-hover-bg: $color-white-glass-10;
$pagination-hover-color: $primary;
$pagination-color: $color-white-glass-50;
$pagination-disabled-bg: transparent;
$pagination-active-bg: transparent;
$pagination-active-color: white;
$pagination-focus-bg: transparent;

// badge
$badge-font-weight: 500;
$badge-font-size: 13px;
$badge-border-radius: 0.5rem;
$badge-padding-x: 0.5rem;
$badge-padding-y: 0.5rem;

//Avatars
$avatar-logo-border-radius: 20%;
$avatar-bg-primary: $color-mango;
$avatar-bg-secondary: $color-peach;

// FZ variables
$header-height: 80px;
$header-height-mobile: 60px;
$site-max-width: 1035px;
$footer-height: 120px;
$footer-height-mobile: 200px;

$z-index-header: 20;
$z-index-user-menu: 30;
$z-index-main-nav: 40;
$z-index-bottom-nav: 50;

//Main Nav
$main-nav-width: 160px;
$main-nav-animation-speed: 0.2s;
$main-nav-item-border-radius: 8px;
$main-nav-padding: math.div($grid-gutter-width,2);

//Main Content
$main-content-padding: 1.5rem;

// Toaster
$toast-max-width: 300px;
$toast-padding-x: .75rem;
$toast-padding-y: .75rem;
$toast-font-size: 13px;
$toast-color: black;
$toast-background-color: white;
$toast-header-background-color: $color-black-glass-30;
$toast-header-color: black;
$toast-border-width: 0;
$toast-border-color: transparent;
$toast-border-radius: 5px;

$popover-header-color: black;
$popover-body-color: black;
$popover-header-padding-x: 8px;
$popover-header-padding-y: 8px;
$popover-body-padding-x: 8px;
$popover-body-padding-y: 8px;

//Alert
$alert-padding-y: 0.5rem;
$alert-bg-warning: fade_out($warning, .85);
$alert-bg-success: fade_out($success, .85);
$alert-bg-info: fade_out($info, .85);
$alert-bg-danger: fade_out($danger, .85);

//Spinner
$spinner-border-width-sm: 3px;

//Progressbar
$progress-bar-bg: $color-white-glass-10;

// Container vars
$fixed-bottom-navbar-height: 80px;
$operations-subnav-height: 45px;
$operations-sub-subnav-height: 45px;
$no-sub-prompt-height: 50px;


// Calculations

@function remToPx($rem, $base: 16) {
  // strip rem value, times by base rem 16, and then add 0px te return a true px value and not string.
  @return math.div($rem, $rem * 0 + 1) * $base + 0px
}

// calculate total generic height of the fixed elements on each page. (current result 173px)
$main-container-fixtures-height: $header-height + $breadcrumb-container-height + remToPx($breadcrumb-spacer) + remToPx($main-content-padding);
