@import "_flyte_vars";

.resize-y-container {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;

  .resize-column-top {
    position: relative;
    flex: 0 0 auto;
    padding-bottom: 16px;
  }
  .resize-column-bottom {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    .map-locations-toggle + .card-body >.row >.col { flex: 1 0 50%; }
    .card-body {
      flex: 1 0 auto;
    }
  }
  .resize-handle-bottom {
    position: absolute;
    height: 16px;
    cursor: row-resize;
    width: 100%;
    bottom: 0;
    left: 0;
    border-top: 2px solid white;
    border-bottom: 2px solid white;
    background-color: white;
    &::before {
      z-index: 10;
      content:'';
      display: block;
      position: absolute;
      left: 50%;
      top: 50%;
      width: 40px;
      height: 6px;
      transform: translate(-50%, -50%);
      border-top: 1px solid $color-charcoal3;
      border-bottom: 1px solid $color-charcoal3;
    }
    &:hover {
      background: rgba(255,255,255, .8);
      &::before { border-color: $color-charcoal2; }
    }
  }
}

.resize-x-container {
  position: relative;
  display: flex;
  width: 100%;
  .card--operations {
    @include calcPageHeight(0);
  }
  &.has-page-prompt {
    .card--operations {
      @include calcPageHeight($no-sub-prompt-height);
    }
  }
  &.has-bottom-nav {
    .card--operations {
      @include calcPageHeight($fixed-bottom-navbar-height);
    }
    &.has-page-prompt {
      .card--operations {
        @include calcPageHeight($fixed-bottom-navbar-height + $no-sub-prompt-height);
      }
    }
  }
  &.has-sub-subnav {
    .card--operations {
      @include calcPageHeight($operations-sub-subnav-height);
    }
    &.has-page-prompt {
      .card--operations {
        @include calcPageHeight($operations-sub-subnav-height + $no-sub-prompt-height);
      }
    }
  }
  &.has-bottom-nav.has-sub-subnav {
    .card--operations {
      @include calcPageHeight($operations-sub-subnav-height + $fixed-bottom-navbar-height);
    }
    &.has-page-prompt {
      .card--operations {
        @include calcPageHeight($operations-sub-subnav-height + $fixed-bottom-navbar-height + $no-sub-prompt-height);
      }
    }
  }
  .resize-column-left {
    position: relative;
    flex: 0 0 auto;
    width: 100%;
    &.show-interaction-panel {
      width: calc(100% / 2);
      padding-right: 16px;
    }
    &.resized:not(.show-interaction-panel) {
      width: 100% !important;
    }
    &:not(.show-interaction-panel) {
      .resize-handle-right { display: none; }
    }
  }
  .resize-column-right {
    flex: 0 0 auto;
    width: calc(100% / 2);
    position: sticky;
    position: -webkit-sticky;
    right:0;
    bottom: 0;
    top: 110px;
    overflow: auto;
  }
  .resize-handle-right {
    position: absolute;
    height: 100%;
    cursor: col-resize;
    width: 10px;
    right: 3px;
    border-radius: 5px;
    top: 0;
    &::before {
      z-index: 10;
      content:'';
      display: block;
      position: absolute;
      left: 50%;
      height: 40px;
      width: 6px;
      top: 40vh;
      transform: translate(-50%, -50%);
      border-left: 1px solid $color-white-glass-50;
      border-right: 1px solid $color-white-glass-50;
    }
    &:hover {
      background: rgba(255,255,255,0.05);
      &::before {
        border-color: white;
      }
    }
  }
}

.resize-ghost-element {
  position: absolute !important;
  top:0 !important;
  left: 0 !important;
}

.show-on-column-sm { display: none; }

.show-interaction-panel {
  &.left-sized-sm {
    .show-on-column-sm { display: block; }
    .hide-on-column-sm { display: none; }
    .location-tools-wrap {
      max-width: 100%;
    }
    .btn-group-inflight {
      .btn {
        padding-left: 10px;
        padding-right: 10px;
      }
    }
    .real-time-switch .form-check-switch {
      margin-right: 0 !important;
    }
    .inflight-section {
      &--timer {
        margin-bottom: 0;
      }
    }
    .card--block-link-stop-propagation {
      .info-item {
        margin-bottom: 0.5rem;
      }
    }
    .card--operations {
      &__body { padding: $card-spacer-y*.75 $card-spacer-x*.75; }
    }
    .row-allow-size-sm-resize {
      >[class^="col"] {
        flex: 0 0 auto;
        width: 100%;
      }
      &.weather-widget {
        .weather-day-detail__day { padding-right: 0; }
      }
    }
  }
  &.left-sized-md {
    .row-allow-size-md-resize {
      >[class^="col"] {
        flex: 0 0 auto;
        width: 100%;
      }
      &.weather-widget {
        .weather-day-detail__day { padding-right: 0; }
      }
    }
  }
  &.left-sized-lg {
    .location-tools-wrap {
      max-width: 50%;
    }
    .inflight-section {
      &--timer {
        margin-bottom: -61px;
        >.row>.col {
          flex: 0 0 auto;
          width: 33.33333333%;
          padding-left: 0.75rem;
        }
      }
      &--dates {
        >.col {
          flex: 0 0 auto;
          width: 66.66666667%;
        }
        .form-floating { min-width: auto; }
      }
      &--battery-voltage {
        >.col {
          flex: 0 0 auto;
          width: 50%;
          .form-floating { min-width: auto; }
        }
      }
    }
  }
}

div[mwlResizable] {
  box-sizing: border-box; // required for the enableGhostResize option to work
}
