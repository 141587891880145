@use "sass:math";
@import "flyte_vars";
@import "../fz-icons/style.css";

.day-swiper {
  .swiper-wrapper {
    align-items: stretch;
  }
  .swiper-slide {
    height: auto;
  }
}

.weather {
  &-day-detail {
    &__day {
      background: $body-bg;
      padding-right: $grid-gutter-width;
      min-height: 100%;
      &--temp {
        font-size: 38px;
        font-weight: 700;
      }
      &--icon {
        font-size: 38px;
      }
      &--wind {
        &-icon { transform: scale(1.5) }
      }
      &--stats {
        i { font-size: 1.6rem; }
      }
    }
    &__summary {
      min-height: 42px;
    }
  }
  &-range {
    padding-top: 2rem;
    .swiper-slide { width: 80px; min-width: 80px; max-width: 80px; }
    .swiper-slide-thumb-active {
      .weather-range--column {
        border-color: $primary;
        &__operation-date {
          border-color: transparent;
        }
      }
    }
    &--column {
      min-height: 226px;
      width: 80px;
      max-width: 80px;
      text-align: center;
      padding: 0.6rem 0.3rem;
      cursor: pointer;
      border: 2px solid $color-white-glass-10;
      border-radius: $border-radius-sm;
      transition: border 0.2s ease-in-out;
      &__operation-date {
        @include fz-gradient;
        color: white;
        .weather-range--kpi {
          background: white;
          color: $color-charcoal1;
          &-number { font-weight: 700; }
        }
      }
      &:hover {
        border-color: $color-white-glass-30;
      }
    }
    &--temp-max { font-size: 1.5rem; white-space: nowrap; }
    &--icon {
      font-size: 1.5rem;
      margin-bottom: 5px;
    }
    &--kpi {
      margin-top: math.div($spacer, 2);
      padding: 0.3rem 1rem 0 1rem;
      border-radius: 8px;
      &-title {
        font-size: 10px;
        margin-bottom: -10px;
      }
      &-number {
        font-size: 2rem;
      }
    }
    &--flight-day {
      font-size: 0.75rem;
      font-weight: 700;
      color: white;
      white-space: nowrap;
    }
  }
  &__hourly {
    background: $body-bg;
    .swiper-slide { width: auto; }
    .weather-hourly {
      text-align: center;
      width: 60px;
      &--wind {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        &-speed { font-size: 0.75rem; margin-top: 3px; }
        &-compass { margin-top: 5px; }
        &-icon { font-size: 1.5rem;}
      }
      &--icon { font-size: 2rem; }
      &--temp {
        font-size: 0.75rem;
      }
    }
  }
  &-widget-portrait {
    .swiper-slide {
      pointer-events: auto !important;
    }
    .weather-day-detail {
      &__day {
        padding-right: 0;
        .lead { font-size: $font-size-base; }
        &--temp {
          font-size: 28px;
        }
        &--icon {
          font-size: 28px;
        }
        &--wind, &--rain { font-size: 12px; line-height: 15px; }
        &__summary {
          min-height: auto; font-size: 12px;
        }
        &--stats {
          .card-body {
            padding: 0.6rem;
          }
        }
      }
    }
    .weather-range {
      padding-top: 0;
      padding-bottom: 20px;
    }
  }
}

.weather-widget {
  &.weather-flight-day {
    .weather-day-detail__summary {
      min-height: auto;
    }
  }
}

@for $i from 0 through 360 {
  .wind-direction-#{$i} {
    display: inline-block;
    transform: rotate(#{$i}deg);
    font-size: 1rem;
  }
}

@for $i from 1 through 5    { .wind-speed-#{$i} .weather-hourly--wind-icon { transform: scale(1); }}
@for $i from 6 through 10   { .wind-speed-#{$i} .weather-hourly--wind-icon { transform: scale(1.2); }}
@for $i from 11 through 15  { .wind-speed-#{$i} .weather-hourly--wind-icon { transform: scale(1.4); }}
@for $i from 16 through 20  { .wind-speed-#{$i} .weather-hourly--wind-icon { transform: scale(1.6); }}
@for $i from 21 through 25  { .wind-speed-#{$i} .weather-hourly--wind-icon { transform: scale(1.8); }}
@for $i from 26 through 30  { .wind-speed-#{$i} .weather-hourly--wind-icon { transform: scale(2); }}
@for $i from 31 through 35  { .wind-speed-#{$i} .weather-hourly--wind-icon { transform: scale(2.2); }}
@for $i from 36 through 40  { .wind-speed-#{$i} .weather-hourly--wind-icon { transform: scale(2.4); }}
@for $i from 41 through 50  { .wind-speed-#{$i} .weather-hourly--wind-icon { transform: scale(2.6); }}
@for $i from 51 through 100 { .wind-speed-#{$i} .weather-hourly--wind-icon { transform: scale(2.8); }}

@mixin icon-thunder       { &::before { @include bi-font("\f2ac");}} //bi-cloud-lightning
@mixin icon-thunder-rain  { &::before { @include bi-font("\f2ab");}} //bi-cloud-lightning-rain
@mixin icon-drizzle       { &::before { @include bi-font("\f29d");}} //bi-cloud-drizzle
@mixin icon-rain          { &::before { @include bi-font("\f2b6");}} //bi-cloud-rain
@mixin icon-rain-heavy    { &::before { @include bi-font("\f2b5");}} //bi-cloud-rain-heavy
@mixin icon-rain-sleet    { &::before { @include bi-font("\f2ba");}} //bi-cloud-sleet
@mixin icon-cloud-snow    { &::before { @include bi-font("\f2bc");}} //bi-cloud-snow
@mixin icon-snow          { &::before { @include bi-font("\f56d");}} //bi-snow
@mixin icon-sun           { &::before { @include bi-font("\f5a2");}} //bi-sun
@mixin icon-cloud-sun     { &::before { @include bi-font("\f2be");}} //bi-cloud-sun
@mixin icon-cloud         { &::before { @include bi-font("\f2c1");}} //bi-cloud
@mixin icon-clouds        { &::before { @include bi-font("\f2c3");}} //bi-clouds
@mixin icon-fog           { &::before { @include bi-font("\f2a0");}} //bi-cloud-fog
@mixin icon-cloud-haze    { &::before { @include bi-font("\f2a7");}} //bi-cloud-haze
@mixin icon-wind          { &::before { @include bi-font("\f61d");}} //bi-wind
@mixin icon-tornado       { &::before { @include bi-font("\f5dc");}} //bi-tornado
@mixin icon-haze          { &::before { @include bi-font("\f44b");}} //bi-justify


// Taken from https://openweathermap.org/weather-conditions

// Thunderstorm
[class^=weather-icon-20] { @include icon-thunder-rain; }
[class^=weather-icon-21] { @include icon-thunder; }
[class^=weather-icon-23] { @include icon-thunder-rain; }


// Drizzle
[class^=weather-icon-30] { @include icon-drizzle; }
[class^=weather-icon-31] { @include icon-rain; }
[class^=weather-icon-32] { @include icon-rain-heavy; }

//Rain
.weather-icon {
  &-500, &-501, &-520 { @include icon-rain; }
  &-502, &-503, &-504, &-521, &-522, &-531 { @include icon-rain-heavy; }
  &-511 { @include icon-rain-sleet; }
}

//Snow
[class^=weather-icon-60] { @include icon-snow; }
.weather-icon {
  &-611, &-612, &-613 { @include icon-rain-sleet; }
  &-615, &-616, &-620, &-621, &-622 { @include icon-cloud-snow; }
}

//Atmosphere
.weather-icon {
  &-741 { @include icon-fog; }
  &-721 { @include icon-cloud-haze; }
  &-701, &-711, &-751, &-761, &762 { @include icon-haze; }
  &-731, &-771 { @include icon-wind; }
  &-781 { @include icon-tornado; }
}

// Clear
.weather-icon {
  &-800 { @include icon-sun }
  &-801 { @include icon-cloud-sun }
  &-802 { @include icon-cloud }
  &-803, &-804 { @include icon-clouds }
}


