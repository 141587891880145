@import "../../_flyte_vars";

:root {
  --fc-small-font-size: .85em;
  --fc-page-bg-color: #313131;
  --fc-neutral-bg-color: hsla(0,0%,82%,.3);
  --fc-neutral-text-color: grey;
  --fc-border-color: #4d4d4d;
  --fc-button-text-color: #fff;

  --fc-button-bg-color: #434343;
  --fc-button-border-color: #434343;

  --fc-button-hover-bg-color: #4b4b4b;
  --fc-button-hover-border-color: #4b4b4b;

  --fc-button-active-bg-color: #FB8953;
  --fc-button-active-border-color: #FB8953;

  --fc-event-bg-color: #FB8953;
  --fc-event-border-color: #FB8953;
  --fc-event-text-color: #fff;
  --fc-event-selected-overlay-color: rgba(0,0,0,.25);
  --fc-more-link-bg-color: pink;
  --fc-more-link-text-color: inherit;
  --fc-event-resizer-thickness: 8px;
  --fc-event-resizer-dot-total-width: 8px;
  --fc-event-resizer-dot-border-width: 1px;
  --fc-non-business-color: hsla(0,0%,84%,.3);
  --fc-bg-event-color: #8fdf82;
  --fc-bg-event-opacity: 0.3;
  --fc-highlight-color: rgba(251,137,83,.35);
  --fc-today-bg-color: rgba(255,255,255,.10);
  --fc-now-indicator-color: red;
  --fc-list-event-hover-bg-color: rgba(255,255,255,.05);
}

@mixin calender-event-spread-style($color) {
  border: 1px solid fade-out($color, .6);
  background-color: fade-out($color, .6);
  transition: all 0.2s ease-in-out;
  &:hover {
    background-color: fade-out($color, .2);
    border-color: fade-out($color, .2);
  }
}

.bullet {
  display: inline-block;
  content: '';
  width: 8px;
  min-width: 8px;
  height: 8px;
  border-radius: 4px;
  margin-right: 3px;
  border: 1px solid white;
}

@mixin calender-event-bullet-style($color) {
  &.fc-event:not(.fc-h-event)::before {
    @extend .bullet;
  }
  &::before {background-color: $color; border-color: $color !important; }
  &:hover::before {background-color: lighten($color, 10%);}
}

@mixin calender-event-bullet-style-2($color) {
  &::before {
    @extend .bullet;
  }
  &::before {background-color: $color; border-color: $color !important; }
  &:hover::before {background-color: lighten($color, 10%);}
}

.fc {
  a {
    color: white;
    cursor: auto;
    &:not(.btn):hover {text-decoration: none;}
  }
  .fc-list {
    .fc-event {
      cursor: pointer;
      &.meeting-safety, &.meeting-qa {
        .fc-list-event-dot { border-color: $color-meeting-event; }
      }
    }
  }
  .fc-event-main {
    display: flex;
    align-items: center;
  }
  .fc-event {
    transition: all 0.2s ease-in-out;
  }
  .fc-list-table {
    .inline-time-stamp { display: none; }
    // list table header cell
    .fc-list-day {
      .fc-cell-shaded {
        padding-top: 4px;
        padding-bottom: 4px;
      }
    }
    .operation {
      &-draft .fc-list-event-dot { border-color: $color-operation-draft }
      &-published .fc-list-event-dot { border-color: $color-operation-published }
      &-completed .fc-list-event-dot { border-color: $color-operation-completed }
    }
  }
  .fc-daygrid {
    .fc-event {
      overflow: hidden;
      padding-left: 3px;
      padding-right: 3px;
      cursor: pointer;
      line-height: 17px;
      font-size: 12px;
      height: 20px;
      strong { font-weight: 500; }
      &.fc-daygrid-dot-event {
        &.meeting-safety, &.meeting-qa { @include calender-event-bullet-style($color-meeting-event) }
        &.operation-published { @include calender-event-bullet-style($color-operation-published)}
        &.operation-completed { @include calender-event-bullet-style($color-operation-completed)}
        &.maintenance-event { @include calender-event-bullet-style($color-maintenance-event)}

        .meeting-safety, .meeting-qa { @include calender-event-bullet-style-2($color-meeting-event) }
        .operation-published { @include calender-event-bullet-style-2($color-operation-published)}
        .operation-completed { @include calender-event-bullet-style-2($color-operation-completed)}
        .maintenance-event { @include calender-event-bullet-style-2($color-maintenance-event)}
      }
      &.occurrence-cancelled {
        opacity: 0.3 !important;
      }
    }
  }
  .fc-h-event, .fc-v-event {
    cursor: pointer;
    overflow: hidden;
    padding-left: 3px;
    padding-right: 3px;
    min-height: 17px;
    line-height: 14px;
    &.meeting-safety, &.meeting-qa { @include calender-event-spread-style($color-meeting-event) }
    &.operation-published { @include calender-event-spread-style($color-operation-published) }
    &.operation-completed { @include calender-event-spread-style($color-operation-completed) }
    &.maintenance-event { @include calender-event-spread-style($color-maintenance-event)}
  }
  .fc-more-popover .fc-popover-body {
    padding: 0.5rem;
    .fc-daygrid-dot-event {
      padding-left: 3px;
      padding-right: 3px;
      cursor: pointer;
      line-height: 17px;
      font-size: 12px;
      height: 20px;
    }
  }
}

.all-day-badge {
  display: inline-block;
  position: relative;
  width: 17px;
  height: 17px;
  vertical-align: middle;
  margin-right: 5px;
  i { font-size: 20px; }
  span { font-size: 7px}
  > * {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.calendar-wrapper {
  height: 300px;
  position: relative;
  overflow: hidden;
  &.full-height { height: auto; }
}

.calendar-widget {
  --fc-border-color: rgba(255,255,255,0.2);
  .fc-dayGridWeek-view {
    --fc-border-color: transparent;
    --calendar-border-color: rgba(255,255,255,0.2);
    .fc-col-header {
      th + th .fc-scrollgrid-sync-inner {
        border-left: 1px solid var(--calendar-border-color);
      }
      th .fc-scrollgrid-sync-inner {
        padding-left: 7px;
        padding-right: 7px;
        a {
          padding-bottom: 5px;
          border-bottom: 1px solid var(--calendar-border-color);
        }
      }
    }
    .fc-scrollgrid-sync-table {
      td + td .fc-daygrid-day-frame {
        border-left: 1px solid $color-white-glass-20;
      }
    }
    .fc-col-header-cell-cushion { word-spacing: 300px; }
    .fc-event, .fc-event-main {
      display: flex;
      flex-direction: column;
      align-items: start;
      width: 100%;
      overflow: hidden;
      height: auto !important;
      .event-time {
        width: 100%;
        margin-bottom: 2px;
        >span { vertical-align: middle }
      }
      .event-title {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 105%;
      }
    }
    .fc-daygrid-day.fc-day-today {
      border-right: 1px solid var(--fc-today-bg-color);
      border-top: 1px solid var(--fc-today-bg-color);
    }
    &.fc-daygrid {
      .fc-daygrid-event-harness {
        padding-left: 7px;
        padding-right: 7px;
      }
      .fc-event {
        overflow: visible;
        border-bottom: 1px solid var(--calendar-border-color);
        padding-bottom: 7px;
        padding-top: 4px;
        flex-direction: column;
        align-items: start;
        border-radius: 0;
        &.fc-daygrid-dot-event.fc-event:not(.fc-h-event) {
          &::before {
            display: none !important; // remove inherited bullet
          }
        }
      }
    }
  }

  .fc-listWeek-view {
    .event-time { margin-right: 5px;}
    .event-time, .event-title { display: inline-block; }
  }

  .fc-dayGridWeek-button, .fc-listWeek-button {
    font-size: 0;
    padding: 0.5rem;
  }

  .fc-dayGridWeek-button::before {
    @include bi-font('\F465'); //bi-layout-three-columns
    font-size: 16px;
  }

  .fc-listWeek-button::before {
    @include bi-font('\F461'); //bi-layout-text-sidebar-reverse
    font-size: 16px;
  }

  .badge.all-day {
    box-shadow: 0 0 0 1px var(--calendar-border-color);
  }

  .fc-list {
    .fc-event {
      .fc-list-event-dot {
        display: none;
      }
    }
  }
}

.hide-operations {
  .operation-event {
    visibility: hidden;
    height: 0 !important;
  }
}

.hide-maintenance {
  .maintenance-event {
    visibility: hidden;
    height: 0 !important;
  }
}

.fc-timegrid {
  .fc-event-main {
    display: block;
  }
}

.fc-direction-ltr .fc-daygrid-event.fc-event-start,
.fc-direction-rtl .fc-daygrid-event.fc-event-end {
  margin-left: 1px;
  margin-right: 1px;
}

.fc-button {
  border-radius: $border-radius-sm !important;
  box-shadow: none !important;
  text-transform: capitalize !important;
}

.fc-direction-ltr .fc-button-group>.fc-button {
  margin-left: 0 !important;
  &:not(:last-child) {
    border-bottom-right-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }
  &:not(:first-child) {
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important;
  }
}

.fc-direction-ltr .fc-list-day-side-text,
.fc-direction-rtl .fc-list-day-text {
  float: none !important;
}
