@font-face {
  font-family: 'FlyteZoneIcons';
  src:
    url('fonts/FlyteZoneIcons.ttf?ie3hix') format('truetype'),
    url('fonts/FlyteZoneIcons.woff?ie3hix') format('woff'),
    url('fonts/FlyteZoneIcons.svg?ie3hix#FlyteZoneIcons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.fzi {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'FlyteZoneIcons' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.fzi-hand:before {
  content: "\e919";
}
.fzi-tasks:before {
  content: "\e918";
}
.fzi-user-multiple:before {
  content: "\e90f";
}
.fzi-cursor:before {
  content: "\e910";
}
.fzi-exclamation-hexagon:before {
  content: "\e911";
}
.fzi-note:before {
  content: "\e912";
}
.fzi-drone-2:before {
  content: "\e913";
}
.fzi-user:before {
  content: "\e914";
}
.fzi-calendar:before {
  content: "\e915";
}
.fzi-close:before {
  content: "\e90e";
}
.fzi-arrow-left:before {
  content: "\e90d";
}
.fzi-current-location:before {
  content: "\e917";
}
.fzi-overview:before {
  content: "\e900";
}
.fzi-document:before {
  content: "\e908";
}
.fzi-compass-point:before {
  content: "\e903";
}
.fzi-drone:before {
  content: "\e901";
}
.fzi-reports:before {
  content: "\e909";
}
.fzi-shield-medical:before {
  content: "\e902";
}
.fzi-shield-user:before {
  content: "\e907";
}
.fzi-map-pin-emergency:before {
  content: "\e904";
}
.fzi-map-pin-landing:before {
  content: "\e905";
}
.fzi-map-pin-launch:before {
  content: "\e906";
}
.fzi-maintenance:before {
  content: "\e90a";
}
.fzi-arrows-left-right:before {
  content: "\e90b";
}
.fzi-files:before {
  content: "\e90c";
}
.fzi-signature:before {
  content: "\e916";
}
.fzi-book:before {
  content: "\e91a";
}
