@media print {
  html, body {
    color: black !important;
    background: white;
  }
  .text-white {
    color: black;
  }
  .main-nav,
  .breadcrumbs-wrap,
  .subnav,
  .header-menu {
    display: none;
  }
  .page-header-row {
    position: relative;
  }
}
