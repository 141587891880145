@use "sass:math";
@import "~ngx-toastr/toastr.css";
@import "../../flyte_vars";

.toast {
  background-image: none;
  position: relative;
  min-width: 300px;
  width: auto;
  max-width: 500px;
  margin-bottom: 0.5rem;
  .toast-body {
    display: flex;
    align-items: center;
    padding-right: 35px;
    position: relative;
    line-height: 1.2;
    &::before {
      font-size: 22px;
      margin-right: 10px;
    }
  }
}

.toast-container > :not(:last-child) {
  margin-bottom: 0.5rem;
}

.toast-top-center {
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.roc-switch-toaster {
  background: $primary;
  padding: 1rem 0 1rem 1rem;
  font-weight: 600;
}

.toast-success {
  .toast-body {
    &::before {
      @include bi-font("\f26b"); //bi-check-circle
      color: $color-green;
    }
  }
}
.toast-error {
  .toast-body {
    &::before {
      @include bi-font("\F337"); //bi-exclamation-octagon
      color: $color-brightred;
    }
  }
}

.toast-warning {
  .toast-body {
    &::before {
      @include bi-font("\f333"); //bi-exclamation-circle
      color: $warning;
    }
  }
}

.toast-info {
  .toast-body {
    &::before {
      @include bi-font("\F430"); //bi-info-circle-fill
      color: $info;
    }
  }
}


.toast-close-button {
  position: absolute;
  text-shadow: none;
  font-weight: normal;
  top: 10px;
  right: 6px;
  color: black;
  opacity: 0.7;
  &::before {
    font-size: 24px;
    @include bi-font("\f62a");
  }
  &:hover {
    opacity: 1;
    color: white;
  }
  span { display: none;}
}

