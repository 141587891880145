@use "sass:math";
@import "avatars_vars";

$avatar-bg-primary: $primary;
$avatar-bg-secondary: $secondary;
$avatar-shadow: 0 0 0px 2px rgba(255, 255, 255, 0.3);
$avatar-bg-default: $color-white-glass-20;

$alphabet: a, b, c, d, e, f, g, h, i, j, k, l, m, n, o, p, q, r, s, t, u, v, w, x, y, z;

@mixin avatar-size($size) {
  width: $size;
  height: $size;
  margin: 1px;
  &:before { box-shadow: inset math.div(-$size, 1.5) 0 rgba(0,0,0,0.2);}
  .avatar__profile-image,
  .avatar__initials,
  .avatar__icon {
    width: $size + 2px;
    height: $size + 2px;
    margin: -1px;
  }
  .avatar__logo {
    width: math.floor($size*.9);
    height: math.floor($size*.9);
  }
  .avatar__icon i {
    font-size: math.floor(math.div($size, $avatar-icon-size-ratio));
    line-height: math.floor(math.div($size, $avatar-icon-size-ratio));
  }
  .avatar__initials-letter {
    font-size: math.floor(math.div($size, $avatar-initials-size-ratio));
    line-height: math.floor(math.div($size, $avatar-initials-size-ratio));
  }
  .upload-btn {
    width: math.div($size, 2.6);
    height: math.div($size, 2.6);
    .text, i { font-size: math.floor(math.div($size, 4.4)); }
  }
}
@mixin avatar-overlap($size) {
  margin-left: math.div($size, 10);
  margin-right: math.div($size, 10);
  li {
    margin-left: math.div(-$size, 10);
    margin-right: math.div(-$size, 10);
  }
}
@mixin avatar-bg-letter($color) {
  background-color: $color;
}

.avatar {
  position: relative;
  border: transparent;
  display: inline-block;
  vertical-align: middle;
  border-radius: 50%;
  &:not(.no-bg) {
    box-shadow: $avatar-shadow;
    background-color: $avatar-bg-default;
  }
  &:after {
    display: block;
    content:'';
    position: absolute;
    top:0;
    border-radius: 50%;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 5;
    background: rgba(0,0,0,0.1);
    transition: all 0.2s ease-in-out;
  }

  &--border-custom {
    box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.125) !important;
    border-radius: $avatar-border-radius-custom;
    &:after { border-radius: $avatar-border-radius-custom; }
    >[class^='avatar'] { border-radius: $avatar-border-radius-custom; }
  }

  &__initials,
  &__icon {
    display: flex;
    overflow: hidden;
    border-radius: 50%;
    .avatar__initials-letter, i {
      margin: auto;
      position: relative;
      text-shadow: $avatar-text-shadow;
    }
  }

  &__profile-image, &__logo {
    display: block;
    position: absolute;
    overflow: hidden;
    border-radius: 50%;
    transition: all 0.2s ease-in-out;
    background: {
      repeat: no-repeat;
      position: center center;
      size: cover;
    }
  }

  &__logo {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-size: contain;
  }

  &__initials {
    font-family: $avatar-font-family;
    font-weight: $avatar-font-weight;
    &-letter {
      display: block;
      color: $avatar-initials-color;
      text-transform: uppercase;
    }
    @each $letter in $alphabet {
      &-bg-#{$letter} {
        @include avatar-bg-letter(map-get($avatar-bg-letter-colors, #{$letter}));
      }
    }
    &-bg-undefined { background-color: #c1c1c1; }
  }
  &__icon {
    background-color: $avatar-icon-bg;
    transition: background-color 0.2s ease-in-out;
    i { color: $avatar-icon-color; }
  }
  &--bg {
    &-primary .avatar__icon { background-color: $avatar-bg-primary; }
    &-secondary .avatar__icon { background-color: $avatar-bg-secondary; }
  }

  &[href] {
    text-decoration: none;
    transition: all 0.2s ease-in-out;
    &:hover {
      text-decoration: none;
      &:after { opacity: 1; }
      box-shadow: none;
    }
  }

  &--size {
    &-xs { @include avatar-size($avatar-size-xs); }
    &-sm { @include avatar-size($avatar-size-sm); }
    &-md { @include avatar-size($avatar-size-md); }
    &-lg { @include avatar-size($avatar-size-lg); }
    &-xl { @include avatar-size($avatar-size-xl); }
    &-xxl { @include avatar-size($avatar-size-xxl); }
  }
  &-list {
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-left: math.div(-$avatar-spacing, 2);
    margin-right: math.div(-$avatar-spacing, 2);
    li {
      margin-bottom: math.div($avatar-spacing, 2);
      margin-left: math.div($avatar-spacing, 2);
      margin-right: math.div($avatar-spacing, 2);
    }
    &--overlapped {
      &-sm { @include avatar-overlap($avatar-size-sm); }
      &-md { @include avatar-overlap($avatar-size-md); }
      &-lg { @include avatar-overlap($avatar-size-lg); }
    }
  }
}
