@import "../../_flyte_vars";

.fz-tour-popover {
  z-index: 800;
  .popover-header {
    background: transparent;
    border: navajowhite;
    font-size: 1.1rem;
    padding: 0.75rem 0.75rem 0 0.75rem;
  }
  .popover-content {
    font-size: $font-size-base;
    padding: 0.75rem;
  }
  .tour-step-navigation {
    display: flex;
    .btn-default {
      color: black;
      padding-right: 0.5rem;
      padding-left: 0.5rem;
      white-space: nowrap;
      border: $btn-border-width solid $primary;
      &:nth-last-child(2) {
        background: $primary;
        color: white;
        margin-left: 5px;
      }
      &:last-child {
        order: -1;
        margin-right: auto;
      }
    }
  }
}

.tour-btn {
  position: fixed !important;
  bottom: 1rem;
  right: 1rem;
  i { font-size: 2rem;}
  &:hover {
    text-shadow: 0 0 4px white;
  }
}
