@use "sass:math";
@import "flyte_vars";

.main-nav {
  @include fz-gradient-side-nav;
  position: fixed;
  top: 0;
  left: -$main-nav-width;
  bottom: 0;
  width: $main-nav-width;
  z-index: $z-index-main-nav;
  padding: $main-nav-padding $main-nav-padding 135px $main-nav-padding ;
  transition: all $main-nav-animation-speed ease-in-out;
  &__toggle {
    position: fixed;
    top: 11px;
    left: 6px;
    color: white;
    cursor: pointer;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    display: flex;
    font-size: 26px;
    i { margin: auto; }
    transition: all $main-nav-animation-speed ease-in-out;
    &:hover {
      background: $color-white-glass-30;
    }
  }
  &__roc {
    .btn { font-weight: 500; }
    .dropdown-toggle {
      height: auto;
      line-height: 1.1;
      flex-direction: column;
      padding: 0.8rem 0.7rem 0.7rem 0.7rem;
      white-space: normal;
      text-align: center;
      .dropdown-text {
        font-weight: 500;
        margin-top: 7px;
      }
      &::after {
        top: 1rem;
      }
    }
    .dropdown-item {
      font-weight: 500;
      line-height: 1.1;
      i {
        opacity: 0.6;
        &.fzi { font-size: 14px;}
      }
      .small { font-weight: normal; }
      &:not(.active):hover {
        background: $color-black-glass-05;
        i { color: $primary; }
      }
      &.active {
        background: transparent;
        border: 2px solid $color-black-glass-15
      }
    }
  }
  &__links {
    overflow-y: auto;
    max-height: calc(100vh - 285px);
    padding-top: $main-nav-padding;
    transition: width $main-nav-animation-speed ease-in-out;
    &::-webkit-scrollbar { display: none; } /* Chrome, Safari and Opera */
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    ul {
      @include list-unstyled;
    }
    li {
      display: block;
      position: relative;
      text-align: center;
      margin-bottom: 0.5rem;
      >a {
        color: white;
        display: block;
        padding: 8px 10px;
        text-decoration: none;
        text-align: center;
        transition: all $main-nav-animation-speed ease-in-out;
        border-radius: $main-nav-item-border-radius;
        .nav-item {
          &__icon {
            display: block;
            text-align: center;
            font-size: 26px;
            margin: auto;
            opacity: 0.5;
            transition: all $main-nav-animation-speed ease-in-out;
          }
          &__text {
            font-weight: 500;
            transition: all $main-nav-animation-speed ease-in-out;
          }
        }
        &:not([href]) {
          color: white;
          cursor: pointer;
        }
        &.disabled { opacity: 0.2;}
        &:hover  {
          text-decoration: none;
          .nav-item__icon { opacity: 1; }
        }
        @media (max-height: 870px) {
          text-align: left;
          >* {
            display: flex;
            align-items: center;
          }
          .nav-item__icon {
            display: inline-block;
            margin-left: 0;
            margin-right: 0.8rem;
            font-size: 22px;
            width: 23px;
          }
        }
      }
      &.active {
        a {
          background: $color-black-glass-15;
          color: #fff;
          .nav-item__icon { opacity: 1; }
        }
      }
    }
  }
  .powered-by {
    overflow: hidden;
    position: absolute;
    text-align: center;
    bottom: 0;
    left: 0;
    right: 0;
    padding: $main-nav-padding;
    opacity: 0.6;
    img {
      width: 40px;
      margin-bottom: 7px;
    }
    @media (max-height: 680px) {
      &::before {
        display: block;
        content:'';
        position: absolute;
        top: 1px;
        left: 0;
        right: 0;
        height: 1px;
        background: $color-white-glass-50;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    left: 0;
    &__toggle,
    .main-nav-overlay {
      display: none;
    }
  }
}
@include media-breakpoint-down(lg) {
  .sidenav-open {
    .main-content { margin-left: 0; }
    .main-nav {
      left: 0;
      &__toggle {
        opacity: 0;
        transition: 0.2s all 0.2s ease;
      }
    }
    .main-nav-overlay {
      position: fixed;
      background: rgba(0,0,0,0.4);
      top: 0;
      left: 0;
      bottom:0;
      right:0;
      z-index: $z-index-main-nav - 1;
    }
  }
}
$secondary-nav-width: 300px;
.offset-secondary-nav {
  margin-left: -$secondary-nav-width;
}


.secondary-nav-container {
  position: relative;
  min-height: calc(100vh - $main-container-fixtures-height);
  .secondary-nav {
    min-height: 100%;
    display: flex;
    align-content: stretch;
    position: absolute;
    width: $secondary-nav-width;
    padding-right: 1.5rem;
  }
  .secondary-container {
    margin-left: $secondary-nav-width;
  }
}
// TODO: @NICOLE FIND BETTER WAY IN SCSS
@property --colorMango {
  syntax: '<color>';
  initial-value: $color-mango;
  inherits: false;
}

@property --colorPeach {
  syntax: '<color>';
  initial-value: $color-peach;
  inherits: false;
}
.vert-nav-container {
  width: 100%;
  position: sticky;
  top: 100px;
  bottom: 20px;
  max-height: calc(100vh - $main-container-fixtures-height);
  background: #393939;
  padding: 1rem;
  border: 1px solid $color-white-glass-10;
  overflow-y: scroll;
  border-radius: $border-radius-sm;
  &.no-icon-right .nav-link { justify-content: normal;}
  .nav-link {
    margin-bottom: 1rem;
    padding: 1.1rem;
    max-height: 70px;
    display: flex;
    align-items: center;
    color: white;
    cursor: pointer;
    --colorMango: #464646FF; --colorPeach: #464646FF;
    background: $color-mango linear-gradient(264deg, var(--colorMango), var(--colorPeach));
    transition: --colorMango .2s, --colorPeach .2s ease-in-out;
    .fzi, .bi {
      &:not(.bi-arrow-right) {
        font-size: 1.5rem;
        vertical-align: middle;
        margin-right: 12px;
        line-height: 1;
        transition: all 0.2s ease-in-out;
      }
    }
    .bi-arrow-right {
      font-size: 1.2rem;
      line-height: 1rem;
      transition: all 0.2s ease-in-out;
    }
    &.active {
      --colorMango: $color-peach;
      --colorPeach: $color-mango;
    }
    &:hover {
      text-decoration: none;
      --colorMango: $color-peach;
      --colorPeach: $color-mango;
      .bi-arrow-right { transform: translateX(4px);}
    }
    &.active, &:hover {
      .text-danger, .text-warning { color: white !important;}
    }
  }
}
