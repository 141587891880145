@import "@ng-select/ng-select/themes/default.theme.css";
@import "../../flyte_vars";

.ng-select {
  width: 100%;
  min-width: 200px;
  &:focus, &:focus-within {
    >.ng-select-container {
      border-color: $input-focus-border-color;
    }
  }
  &.ng-select-opened>.ng-select-container {
    background-color: $input-focus-bg !important;
    border-color: $input-focus-border-color !important;
    box-shadow: none;
  }
  &.ng-select-focused:not(.ng-select-opened)>.ng-select-container {
    border-color: $input-focus-border-color;
  }
  .ng-select-container {
    border-color: $input-border-color;
    border-radius: $btn-border-radius !important;
    background-color: $input-bg;
    color: $body-color;
    box-shadow: none !important;
    transition: all 0.2s ease-in-out;
    min-height: $floating-form-height-sm;
    .ng-value-container {
      padding-left: $input-padding-x;
      padding-right: $input-padding-x;
      .ng-input>input { color: $body-color !important;}
      .ng-placeholder { color: $color-white-glass-70; }
    }
    &.ng-has-value {
      .ng-placeholder {
        display: block;
        transform: scale(0.75) translateY(-1rem);
        transform-origin: left;
        position: absolute;
        color: $color-white-glass-50;
      }
      .ng-input>input { color: $body-color !important; }
    }
  }
  &.ng-untouched {
    .ng-select-container.ng-has-value {
      .ng-clear-wrapper { display: none; }
    }
  }
  &.ng-touched {
    &.ng-invalid {
      .ng-select-container { border: 1px solid $danger !important; }
    }
  }
  .ng-arrow-wrapper {
    width: 38px;
    padding:0;
    .ng-arrow {
      top: auto !important;
      margin-top: 1px !important;
      border: none;
      width: auto !important;
      height: auto !important;
      &:after { @include bi-font("\f282"); }
    }
  }
  .ng-clear-wrapper {
    width: auto !important;
    padding:0 2px 0 6px;
    color: white;
    text-align: center;
    &::after {
      @include bi-font("\f62a");
      font-size: 1.4rem;
      vertical-align: middle;
    }
    .ng-clear {
      display: none !important;
      top: auto !important;
      border: none;
      width: auto !important;
      height: auto !important;

    }
  }
  &.ng-select-single {
    .ng-select-container {
      .ng-value-container  {
        .ng-input {
          top: 50%;
          transform: translateY(-50%);
          padding-left: $input-padding-x;
          padding-right: $input-padding-x;
        }
      }
      &.ng-has-value {
        .ng-value, .ng-input {
          padding-top: 17px;
          padding-bottom: 4px;
        }
      }
    }
  }
  &.ng-select-multiple {
    .ng-select-container {
      .ng-value-container  {
        .ng-placeholder { top: auto; }
        .ng-value {
          display: flex;
          align-items: center;
          flex-direction: row-reverse;
          background: $color-white-glass-10;
          color: white;
          padding: 4px 8px;
          font-size: $input-font-size;
          border-radius: $border-radius-sm;
          &.ng-value-disabled {
            background: $color-white-glass-10;
            color: $color-white-glass-50;
          }
          &-icon {
            border: none !important;
            &:hover {
              color: $primary !important;
              background: none !important;
            }
          }
        }
      }
      &.ng-has-value {
        .ng-placeholder {
          display: none;
        }
      }
    }
  }
  &.ng-select-disabled {
    >.ng-select-container {
      cursor: not-allowed;
      background-color: fade-out($color-charcoal2, 0.7);
      color: fade-out($input-color, 0.5);
      border-color: $color-white-glass-10;
      .ng-value-container {
        .ng-placeholder,
        .ng-value .ng-value-label {
          color: fade-out($input-color, 0.5) !important;
        }
      }
    }
  }
}

// custom label position for dropdowns containing member avatars
.member-select.ng-select {
  .ng-select-container.ng-has-value {
    .ng-placeholder {
      transform: scale(0.75) translateY(-1rem) translateX(47px);
    }
    .ng-value .avatar { margin-top: -14px;}
    .ng-input { padding-left: 47px;}
  }
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-disabled {
  color: $color-charcoal1; opacity: 0.6;
}

.ng-dropdown-panel.ng-select-bottom,
.ng-dropdown-panel.ng-select-top {
  border-radius: $btn-border-radius;
  .ng-dropdown-panel-items {
    padding: $dropdown-padding-x;
    max-height: 278px;
    .ng-option {
      border-radius: $btn-border-radius !important;
      + .ng-option { margin-top: $dropdown-item-padding-y; }
      &:hover {
        background: $color-black-glass-10;
      }
      &.ng-option-marked {
        background: $color-black-glass-10;
      }
      &.ng-option-disabled { cursor: auto; }
      &.ng-option-selected  {
        .ng-option-label { font-weight: normal; }
        background: $primary;
        color: white;
      }
    }
  }
}
.ng-dropdown-panel.ng-select-bottom {
  margin-top: $dropdown-padding-x;
}
.ng-dropdown-panel.ng-select-top {
  margin-bottom: $dropdown-padding-x;
}

// In-flight flight dropdown
ng-select.flight-select {
  .ng-select-container {
    background-color: $color-white-glass-10 !important;
    border-color: $color-white-glass-10 !important;
    height: 46px !important;
    border-right: 0;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;

    .ng-arrow-wrapper {
      background: $color-white-glass-10;
      border-radius: 0;
      padding: 12px 16px 11px 16px;
      width: 48px;
      height: 46px;
    }
    &.ng-has-value {
      .ng-value {
        padding: 0 10px 0 0 !important;
        flex: 1 1 auto;
      }
      .ng-input { padding: 0 !important; }
    }
  }
  &.ng-select-opened>.ng-select-container {
    border-color: $color-white-glass-30 !important;
    box-shadow: none;
  }
  .ng-dropdown-panel {
    background-color: $color-charcoal2;
    border-width: 0;
    box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 35%);
    .ng-option {
      background-color: $color-charcoal2;
      color: white;
      &.ng-option-marked {
        background-color: $color-charcoal1;
        color: white;
      }
      &.ng-option-selected {
        background-color: $color-charcoal1 !important;
      }
    }
  }
  &.ng-select-disabled {
    .ng-select-container {
      cursor: not-allowed;
      background-color: $color-charcoal1;
    }
  }
}

.select-sm-no-label {
  .ng-select-container {
    min-height: auto;
    height: 32px !important;
  }
  .ng-has-value .ng-value,
  .ng-has-value .ng-input {
    padding-bottom: 0 !important;
    padding-top: 0 !important;
  }
  .ng-option { padding: 5px 10px !important;}
}
