
.bs-datepicker {
  font-family: $font-family-base;
  border-radius: $input-border-radius;
  box-shadow: $box-shadow;
  &-container { padding: 0; }
  &-head {
    background: none !important;
    button {
      color: black;
      &.previous, &.next {
        color: $primary;
        span { display: none; }
        &:before {
          display: block;
          font-size: 18px;
        }
      }
      &.previous:before { @include bi-font("\f230"); }
      &.next:before { @include bi-font("\f234"); }
    }
  }
  &-body {
    border: none;
    table {
      &.days span {
        width: 37px;
        height: 37px;
        line-height: 37px;
      }
      //show only first letter of weekdays
      [aria-label="weekday"] {
        padding-bottom: 5px;
        font-size: 0;
        &:first-letter {
          font-size: $font-size-base;
          color: $primary
        }
      }
    }
  }
}

