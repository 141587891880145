@use "sass:math";
@import '@swimlane/ngx-datatable/index.css';
@import '@swimlane/ngx-datatable/assets/icons.css';
@import "../../_flyte_vars";
@import "datatable_vars";

/*
bootstrap table theme customised for FlyteZone
*/

.datatable-wrapper {
  position: relative;
  .table-link {
    color: $body-color;
    display: block;
    transition: color 0.1s ease-in-out;
    &:hover {
      color: $primary;
      text-decoration: none;
    }
  }
  .row-disabled { opacity: 0.5 }
  .status-dot {
    width: 14px;
    height: 14px;
    border-radius: 7px;
    vertical-align: middle;
    display: inline-block;
    border: 2px solid white;
    margin-right: 5px;
  }
  .datatable-border {
    margin-bottom: 1rem;
    position: relative;
    border-radius: $border-radius-sm;
    background: rgba(255,255,255,.03);
    border: 1px solid $color-white-glass-10;
    padding: 0 $card-spacer-x;
  }
  .datatable-selected-items-actions {
    height: $datatable-header-cell-height;
    position: absolute;
    top: calc(#{$datatable-header-cell-padding-y} + #{$datatable-border-width});
    left: calc(#{$datatable-header-cell-padding-x*3} + #{$datatable-spacer} + #{$datatable-checkbox-width} + #{$datatable-border-width});
    right: $datatable-spacer;
    opacity: 0;
    background: $datatable-bg;
    overflow: hidden;
    z-index: 10;
    transition: opacity 0.2s ease-in-out;
    &.show { opacity: 1 }
    .action-icon { line-height: $datatable-header-cell-height;}
  }
  .form-floating {
    min-width: auto;
    margin-top: -14px;
    margin-bottom: -14px;
    label, bo-label { display: none; }

    .form-control {
      padding: 8px 8px !important;
      height: 36px !important;
    }
  }
  .form-multi-input .form-floating {
    margin-top: 0;
    margin-bottom: 0;
    .form-control {
      min-width: auto;
    }
  }
  .ng-select { min-width: auto; }
  .ng-select.ng-select-single .ng-select-container {
    height: 36px !important;
    min-height: 36px;
    margin-top: -14px;
    margin-bottom: -14px;
  }
  .ng-select.ng-select-single .ng-select-container.ng-has-value {
    .ng-value, .ng-input {
      padding-top: 4px;
    }
  }
  .ng-select .ng-select-container.ng-has-value .ng-placeholder { display: none; }
  .ng-dropdown-panel { min-width: 150px;}

  &.fixed-row-height-inline-edit {
    .form-floating {
      margin-top: -7px;
    }
  }
}

.allow-overflow {
  max-height: none;
  .ngx-datatable.bootstrap {
    overflow: visible;
    .datatable-body .datatable-body-row .datatable-body-cell {
      overflow: visible;
    }
    .datatable-body .datatable-scroll {
      max-height: none;
      overflow: visible;
    }
  }
}

.table-loader {
  padding: 50px;
  text-align: center;
}

.datatable-filter {
  margin-bottom: $grid-gutter-width;
  .ng-select { min-width: 250px;}
  .dropdown-menu {
    min-width: 250px;
  }
  .dropdown-item {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    &.active {
      &::after {
        @include bi-font("\f62a");
        vertical-align: middle;
        font-size: 1.5rem;
        float: right;
      }
    }
  }
  .form-floating .form-control { min-width: 250px;}
  .search-box {
    .form-floating .form-control {
      padding-right: 40px;
    }
    position: relative;
    .clear-filter {
      position: absolute;
      top: 13px;
      right: 10px;
      font-size: 1.2rem;
      line-height: 1.2rem;
      color: white;
      transition: all 0.2s ease-in-out;
      &:not([disabled]):not(.text-muted):hover { color: $primary;  cursor: pointer;}
    }
  }
}

.datatable-footer-pagesize {
  margin-top: -52px;
  margin-bottom: 30px;
  margin-left: 120px;
  position: absolute;
  .page-size-select {min-width: auto;
    .ng-arrow { font-size: 0.9rem !important;}
  }
  &.no-pagination-select {
    margin-left: 5px;
  }
}

.table-footer {
  .ng-select {
    min-width: auto;
    width: 80px;
  }
  .pagination {
    margin-bottom: 0;
    .page-link:hover { text-decoration: none; }
  }
}

$row-height: 51px;
$row-margin: 7px;

.ngx-datatable.bootstrap {
  box-shadow: none;
  font-size: 0.875rem;
  .datatable-header {
    height: unset !important;
    .datatable-header-cell {
      vertical-align: bottom;
      padding: $datatable-header-cell-padding-y $datatable-header-cell-padding-x;
      .datatable-header-cell-label {
        line-height: $datatable-header-cell-height;
      }
    }
  }
  .datatable-row-center { align-items: center; }
  &.min-height-5 .datatable-scroll { height: ($row-height * 5) + (6 * $row-margin) +1; }
  &.min-height-10 .datatable-scroll { height: ($row-height * 10)  + (11 * $row-margin) +1;}
  &.min-height-15 .datatable-scroll { height: ($row-height * 15)  + (16 * $row-margin) +1;}
  .datatable-body {
    .datatable-scroll {
      display: block;
      max-height: calc(100vh - 389px);
      width: 100% !important;
      overflow-y: auto;
      overflow-x: clip;
    }
    .datatable-row-wrapper {
      vertical-align: top;
      background: $color-white-glass-10;
      margin: 0 0 .5rem 0;
      border-radius: $border-radius-sm;
      .datatable-body-cell {
        padding: 12px 0.75rem;
        text-align: left;
        vertical-align: top;
        .badge.status-label { margin: -2px auto;}
        .btn-sm { margin: -6px auto;}
      }
      .datatable-group-header {
        .expandable-row {
          cursor: pointer;
          padding: 0.65rem 1rem;
          border-radius: $border-radius-sm;
          transition: all 0.2s ease-in-out;
          font-weight: 600;
          &:hover { background-color: $color-white-glass-10}
        }
        ~ .datatable-body-row {
          background: $color-black-glass-10;
          border-top: 1px solid $color-white-glass-10;
        }
      }
      .datatable-row-detail {
        padding-right: math.div($grid-gutter-width, 2);
        padding-left: 50px;
        height: auto !important;
        @include fz-border-top-dashed;
        .row-datatable-row-detail {
          height: auto !important;
          align-items: center;
          padding-top: math.div($grid-gutter-width, 2);
          padding-bottom: math.div($grid-gutter-width, 2);
        }
      }
      .datatable-row-group {
        .datatable-body-cell:last-child {
          .full-cell-click {
            border-radius: $border-radius-sm;
          }
        }
        &:hover, &:active {
          .full-cell-click:not(.no-hover) {
            background: $color-white-glass-05;
            .text-overflow-ellipses:hover {
              background: #555555;
            }
          }
        }
      }
    }
    .empty-row {
      $alert-padding-y: 0.75rem !default;
      $alert-padding-x: 1.25rem !default;
      position: relative;
      padding: $alert-padding-y $alert-padding-x;
      margin-bottom: 0;
    }
    .text-overflow-ellipses {
      height: 28px;
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 100%;
      overflow-x: hidden;
      transition: width 0.1s;
      display: block;
      &:hover {
        padding: 1px 10px 1px 0;
        overflow-x: visible;
        position: absolute;
        background: #515151;
        width: auto;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
  .form-check {
    &:not(.form-check-radio) {
      width: $datatable-checkbox-width;
      .form-check-input { margin:0; }
      padding:0;
      margin:0;
    }
  }
  .btn {
    white-space: nowrap;
  }
  .datatable-footer {
    border-top: 3px solid #4a4a4a;
    margin-top: -1px;
    .page-count {
      line-height: 50px;
      height: 50px;
      padding: 0 0.75rem;
      &::before {
        display: inline;
        content: 'Results:';
      }
    }
    .datatable-pager {
      margin: 0 10px;
      vertical-align: top;
      ul {
        li {
          margin: 10px 0;
          &:not(.disabled) {
            &.active,
            &:hover {
              a {
                background-color: #545454;
                font-weight: bold;
              }
            }
          }
        }
      }
      a {
        height: 22px;
        min-width: 24px;
        line-height: 22px;
        padding: 0;
        border-radius: 3px;
        margin: 0 3px;
        text-align: center;
        text-decoration: none;
        vertical-align: bottom;
        color: #ededed;
      }
      .datatable-icon-left,
      .datatable-icon-skip,
      .datatable-icon-right,
      .datatable-icon-prev {
        font-size: 18px;
        line-height: 27px;
        padding: 0 3px;
      }
    }
  }
  &.row-grouping-documents-table {
    .text-overflow-ellipses:hover { background: #494949; }
    .datatable-footer {
      .page-count { display: none; }
    }
  }
  // Summary row styles
  .datatable-summary-row {
    .datatable-body-row {
      .datatable-body-cell {
        font-weight: bold;
      }
    }
  }
  .full-cell-click {
    padding: 14px 0.75rem;
    margin: -14px -0.75rem;
    min-height: 50px;
    cursor: pointer;
    transition: all 0.2s;
    display: block;
    color: white;
  }
  a.full-cell-click {
    .text-overflow-ellipses {
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

// compact padding for modals
.compact-rows {
  .ngx-datatable.bootstrap {
    .datatable-body {
      .datatable-body-row {
        .datatable-body-cell {
          padding-top: 0.5rem;
          padding-bottom: 0.5rem;
        }
      }
    }
  }
}
